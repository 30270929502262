import { NotInterested, ReceiptLong, Warning } from "@mui/icons-material";
import { Box, ListItemButton, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import BusinessDisplay from "../../common/components/data-display/BusinessDisplay";
import {
  displayAdvancedRequestName,
  displayPrice,
} from "../../common/util/DisplayUtil";

export function AdminAdvancedRequestSummary({ ...props }) {
  return <AdvancedRequestSummary {...props} adminView />;
}

export default function AdvancedRequestSummary({
  advancedRequest,
  item,
  adminView = false,
  ...props
}) {
  const request = advancedRequest || item;
  let linkProps = {};
  if (!props.onClick) {
    linkProps = {
      LinkComponent: Link,
      to:
        (adminView
          ? `/app/business-admin/manage-request/${request.id}`
          : `/app/buyer/product-requests/${request.id}`) +
        (request.fulfillmentStatus === "DRAFT" ? "/edit" : ""),
    };
  }
  return (
    <ListItemButton
      sx={{
        mb: 1,
        p: 1,
        maxWidth: "800px",
        display: "flex",
        alignItems: "center",
        borderRadius: 1,
        border: "1px solid #E0E0E0",
      }}
      data-testid={"advanced-request-summary"}
      {...linkProps}
      aria-label={`View request ${request.orderReference ?? ` for ${moment(request.pickupDate).format("M/D/YY")}`}`}
      {...props}
    >
      <Box
        sx={{
          display: "flex",
          minWidth: 0,
          maxWidth: "fit-content",
          mr: 0,
          flexWrap: "wrap",
          gap: 2,
          alignItems: "center",
        }}
      >
        {adminView && (
          <Box sx={{}}>
            {Boolean(request.isLinkedToInvoice) && (
              <Tooltip title="Linked to an invoice">
                <ReceiptLong color="info" />
              </Tooltip>
            )}
            {Boolean(!request.isLinkedToInvoice) && (
              <Tooltip title="Not linked to an invoice">
                <NotInterested />
              </Tooltip>
            )}
          </Box>
        )}
        <Typography
          variant="body1"
          sx={{ fontWeight: 800, width: 130, display: "inline-block" }}
          data-testid="request-pickup-date"
        >
          {/* display date with day of week, day month year */}
          {moment(request.pickupDate).format("ddd, M/D/YY")}
        </Typography>
        <Typography
          sx={{
            textOverflow: "ellipsis",
            overflowX: "hidden",
            whiteSpace: "nowrap",
            display: "inline-block",
            maxWidth: 200,
          }}
          data-testid="request-order-reference"
        >
          {displayAdvancedRequestName(request)}
        </Typography>
        {adminView && (
          <Box sx={{ display: "inline-block" }}>
            <BusinessDisplay
              business={request.buyerBusiness}
              sx={{ maxWidth: 250 }}
            />
          </Box>
        )}
        {request.pickupLocation?.name && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption">Pickup Option</Typography>
            <Typography
              variant="body1"
              sx={{ display: "inline-block" }}
              data-testid="order-sale-date"
            >
              {request.pickupLocation.name}
            </Typography>
          </Box>
        )}

        {request.fulfillmentStatus === "DRAFT" ? (
          <Box sx={{ display: "inline-block" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Warning color="warning" sx={{ marginRight: 1.5 }} />
              <Tooltip title="This request is a draft and has not been submitted">
                <Typography variant="body1" color="warning">
                  Draft
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        ) : (
          ""
        )}
        <Typography
          variant="body1"
          sx={{
            justifySelf: "space-between",
            fontWeight: 800,
            maxWidth: 120,
            marginLeft: "auto",
          }}
          data-testid="request-estimated-total"
        >
          {displayPrice(request.estimatedTotal)}
        </Typography>
      </Box>
    </ListItemButton>
  );
}
