/* eslint-disable no-console */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { AuthenticationService } from "../service/AuthenticationService";
import { MasqueradeAuthService } from "../service/MasqueradeAuthService";
import { PermissionService } from "../service/PermissionService";
import { useCollective } from "./CollectiveContext";

export const CURRENT_USER_STORAGE_KEY = "APP_CURRENT_USER";
export const DEFAULT_SUPPLIER_KEY = "APP_DEFAULT_SUPPLIER";
export const DEFAULT_BUYER_KEY = "APP_DEFAULT_BUYER";
export const AuthContext = React.createContext();

export default function AuthContextProvider({ children }) {
  const { collectiveInfo } = useCollective();
  const [defaultSupplier, setDefaultSupplier] = useLocalStorage(
    DEFAULT_SUPPLIER_KEY,
    null
  );
  const [defaultBuyer, setDefaultBuyer] = useLocalStorage(
    DEFAULT_BUYER_KEY,
    null
  );
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [currentUser, setCurrentUser] = useLocalStorage(
    CURRENT_USER_STORAGE_KEY,
    null
  );

  async function masqueradeAsUser(userId) {
    return MasqueradeAuthService.masqueradeAsUser(userId)
      .then(logInAsUser)
      .catch((e) => {
        alert("error logging in as user");
        console.error(e);
      });
  }

  async function masqueradeLogout() {
    return MasqueradeAuthService.masqueradeLogout()
      .then(logInAsUser)
      .catch((e) => {
        alert("error logging out as user");
        console.error(e);
      });
  }

  function setDefaultBusinesses(user) {
    const businesses = user.businessRoles;
    if (businesses?.length) {
      const suppliers = businesses.filter(
        (bizRole) => bizRole.supplierStatus === "yes"
      );
      if (suppliers.length === 1) {
        setDefaultSupplier({ ...suppliers[0], id: suppliers[0].businessId });
      } else {
        if (
          // Ensure the default supplier is still in the list of suppliers
          defaultSupplier &&
          !suppliers.find((supplier) => supplier.id === defaultSupplier.id)
        ) {
          setDefaultSupplier(null);
        }
      }
      const buyers = businesses.filter(
        (bizRole) => bizRole.buyerStatus === "yes"
      );
      if (buyers.length === 1) {
        setDefaultBuyer({ ...buyers[0], id: buyers[0].businessId });
      } else {
        if (
          // Ensure the default buyer is still in the list of buyers
          defaultBuyer &&
          !buyers.find((buyer) => buyer.id === defaultBuyer.id)
        ) {
          setDefaultBuyer(null);
        }
      }
    }
  }

  function logInAsUser(user) {
    setCurrentUser({ ...user });
    setDefaultBusinesses(user);
    navigate("/app");
  }

  async function logOut() {
    setCurrentUser(null);
    return AuthenticationService.logout();
  }

  if (!window.globalLogout) {
    window.globalLogout = logOut;
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: Boolean(currentUser),
        isMasquerading: Boolean(currentUser?.masqueradeUser),
        currentUser,
        masqueradeAsUser,
        logInAsUser,
        masqueradeLogout,
        logOut,
        isMemberOfAnyBuyer: PermissionService.isMemberOfAnyBuyer(currentUser),
        isMemberOfAnySupplier:
          PermissionService.isMemberOfAnySupplier(currentUser),
        isOwnerOfCollective: PermissionService.isOwnerOfCollective(
          collectiveInfo,
          currentUser
        ),
        isMemberOfCollective: PermissionService.isMemberOfCollective(
          collectiveInfo,
          currentUser
        ),
        isMemberOfBusiness: (businessId) => {
          return PermissionService.isMemberOfBusiness(businessId, currentUser);
        },
        supplierBusinesses: currentUser?.businessRoles.filter(
          (bizRole) => bizRole.supplierStatus === "yes"
        ),
        buyerBusinesses: currentUser?.businessRoles.filter(
          (bizRole) => bizRole.buyerStatus === "yes"
        ),
        isMemberOfPlatinumBuyer:
          PermissionService.isMemberOfPlatinumBuyer(currentUser),
        defaultBuyer,
        setDefaultBuyer,
        defaultSupplier,
        setDefaultSupplier,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return React.useContext(AuthContext);
}
