import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import { useState } from "react";

export function FormTextField({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  autoCapitalize,
  ...props
}) {
  const autoCapitalizeSetting =
    autoCapitalize ||
    (["email", "password"].includes(props.type) ? "off" : "sentences");
  function handleChange(event) {
    let newValue = event.target.value;
    if (!value && newValue?.length === 1 && autoCapitalizeSetting !== "off") {
      // Capitalize the first letter of the input
      newValue = newValue.toUpperCase();
    }
    return onChange({ target: { name: name, value: newValue } });
  }

  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      size="small"
      fullWidth
      id={name}
      data-testid={name}
      autoCapitalize={autoCapitalize || "off"}
      InputProps={{
        endAdornment:
          props.type === "password" ? (
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(e) => e.preventDefault()}
              tabIndex={-1}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          ) : null,
      }}
      {...props}
      type={props.type === "password" && showPassword ? "text" : props.type}
      onChange={handleChange}
      onBlur={onBlur}
      name={name}
      value={value || ""}
      error={touched[name] && Boolean(errors[name])}
      helperText={errors[name]}
    />
  );
}
