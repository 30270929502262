import { Box } from "@mui/material";
import React from "react";
import RecentAdvancedRequestSearch from "./RecentAdvancedRequestSearch";
import RecentPresaleOrderSearch from "./RecentPresaleOrderSearch";
import RecentSalesLogSearch from "./RecentSalesLogSearch";

export default function PointOfSaleQuickSelectOptions() {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-around",
        p: 1,
        gap: 2,
      }}
    >
      <RecentSalesLogSearch />
      <RecentAdvancedRequestSearch />
      <RecentPresaleOrderSearch />
    </Box>
  );
}
