import { CreditCard } from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import TypographyEllipsis from "./TypographyEllipsis";

function getIconPath(paymentMethod) {
  if (!paymentMethod) {
    return null;
  }
  if (paymentMethod.name?.toLowerCase().includes("venmo")) {
    return "/img/logo/venmo-logo.ico";
  }
  if (paymentMethod.name?.toLowerCase().includes("check")) {
    return "/img/logo/bank-check.png";
  }
}

export default function PaymentDisplay({ paymentMethod, label, sx, ...props }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        width: "100%",
        ...sx,
      }}
      {...props}
    >
      {paymentMethod?.name && (
        <>
          <Avatar
            src={getIconPath(paymentMethod)}
            sx={{ height: 36, width: 36 }}
          >
            <CreditCard />
          </Avatar>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              flexGrow: 1,
              minWidth: 0,
            }}
          >
            {label && <Typography variant="caption">{label}</Typography>}

            <TypographyEllipsis variant="body1" data-testid="payment-display">
              {paymentMethod.name}
            </TypographyEllipsis>
          </Box>
        </>
      )}
    </Box>
  );
}
