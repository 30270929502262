import axios from "axios";

export const PRESALE_ORDER_BASE_URL =
  process.env.REACT_APP_API_PATH + "/presaleOrder";

const PresaleOrderService = {
  async getCurrentPresaleOrder(marketDate) {
    const response = await axios.get(PRESALE_ORDER_BASE_URL + "/current", {
      params: new URLSearchParams({ marketDate }),
    });
    return response.data;
  },
  async getPresaleOrders(searchCriteria) {
    const response = await axios.post(
      PRESALE_ORDER_BASE_URL + "/search",
      searchCriteria
    );
    return response.data;
  },
  async getPresaleOrder(presaleOrderId) {
    const response = await axios.get(
      PRESALE_ORDER_BASE_URL + "/" + presaleOrderId
    );
    return response.data;
  },
  async addPresaleOrderItem(presaleOrderId, presaleOrderItem) {
    const response = await axios.post(
      PRESALE_ORDER_BASE_URL + "/" + presaleOrderId + "/item",
      presaleOrderItem
    );
    return response.data;
  },
  async updatePresaleOrderItem(presaleOrderId, presaleOrderItem) {
    const response = await axios.put(
      PRESALE_ORDER_BASE_URL +
        "/" +
        presaleOrderId +
        "/item/" +
        presaleOrderItem.presaleOrderItemId,
      presaleOrderItem
    );
    return response.data;
  },
  async deletePresaleOrderItem(presaleOrderId, presaleOrderItemId) {
    const response = await axios.delete(
      PRESALE_ORDER_BASE_URL +
        "/" +
        presaleOrderId +
        "/item/" +
        presaleOrderItemId
    );
    return response.data;
  },
  async deletePresaleOrder(presaleOrderId) {
    const response = await axios.delete(
      PRESALE_ORDER_BASE_URL + "/" + presaleOrderId
    );
    return response.data;
  },
  async submitOrder(presaleOrder) {
    const response = await axios.put(
      PRESALE_ORDER_BASE_URL + `/${presaleOrder.id}/submit`,
      presaleOrder
    );
    return response.data;
  },
  async updateSalesCheckoutStatus(presaleOrderItemId, salesCheckoutStatus) {
    const response = await axios.put(
      PRESALE_ORDER_BASE_URL +
        `/item/${presaleOrderItemId}/salesCheckoutStatus`,
      { salesCheckoutStatus }
    );
    return response.data;
  },
};

export default PresaleOrderService;
