import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import { AxiosError } from "axios";
import React, { createContext, useCallback, useContext, useState } from "react";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmCallback, setConfirmCallback] = useState(null);
  const [confirmCancelCallback, setConfirmCancelCallback] = useState(null);

  const clearConfirmAlert = useCallback(() => {
    setConfirmOpen(false);
    setConfirmMessage("");
    setConfirmCallback(null);
    setConfirmCancelCallback(null);
  }, []);

  const addConfirmAlert = useCallback(({ message, onConfirm, onReject }) => {
    setConfirmMessage(message);
    setConfirmCallback(() => onConfirm);
    setConfirmOpen(true);
    setConfirmCancelCallback(() => onReject);
  }, []);

  const addSuccessAlert = useCallback((message) => {
    addAlert(message, "success");
  }, []);

  const addWarningAlert = useCallback((message) => {
    addAlert(message, "warning");
  }, []);

  const addErrorAlert = useCallback((message, error) => {
    let errMessage = message;
    if (error instanceof AxiosError) {
      if (error.response?.data?.message) {
        errMessage += `: ${error.response.data.message}`;
      } else if (error.message) {
        errMessage += `: ${error.message}`;
      }
    }
    addAlert(errMessage, "error");

    // eslint-disable-next-line no-console
    console.error(error);
  }, []);

  const addAlert = (message, type) => {
    setAlerts((prevAlerts) => [...prevAlerts, { message, type }]);
  };

  const removeAlert = (index) => {
    setAlerts((prevAlerts) => prevAlerts.filter((_, i) => i !== index));
  };

  return (
    <AlertContext.Provider
      value={{
        alerts,
        addSuccessAlert,
        addErrorAlert,
        addWarningAlert,
        addConfirmAlert,
      }}
    >
      {children}
      {Boolean(alerts.length) && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          autoHideDuration={5000}
          onClose={() => removeAlert(0)}
        >
          <Alert severity={alerts[0].type} variant="filled">
            {alerts[0].message}
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={confirmOpen}
        onClose={() => {
          confirmCancelCallback && confirmCancelCallback();
          clearConfirmAlert();
        }}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              confirmCancelCallback && confirmCancelCallback();
              clearConfirmAlert();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              confirmCallback();
              clearConfirmAlert();
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </AlertContext.Provider>
  );
};

export const useAlerts = () => useContext(AlertContext);
