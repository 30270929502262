import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useAlerts } from "../../context/AlertContext";
import BusinessAutocomplete from "../form/BusinessAutocomplete";
import FormCheckboxWithLabel from "../form/FormCheckboxWithLabel";

const validationSchema = Yup.object({
  business: Yup.mixed().required("Business is required").nullable(),
});

const SelectBusinessDialog = ({
  open,
  onClose,
  onSelect,
  currentSelectedBusiness,
  businessAutocompleteProps,
  allowChangingDefault,
}) => {
  const { addWarningAlert } = useAlerts();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid="select-business-dialog"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Select a Business</DialogTitle>
      <Formik
        initialValues={{
          business: currentSelectedBusiness || null,
          shouldSetAsDefault: allowChangingDefault ? false : null,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (!values.business) {
            addWarningAlert("Please select a business");
          }
          onSelect(values.business, values.shouldSetAsDefault);
          onClose();
        }}
      >
        {({ values, setFieldValue, errors, touched, handleSubmit }) => (
          <Form autoComplete="off">
            <DialogContent>
              <Field
                name="business"
                component={BusinessAutocomplete}
                label="Business"
                {...businessAutocompleteProps}
                onChange={(e) => {
                  setFieldValue("business", e.target.value, false);
                  handleSubmit();
                }}
              />
              {allowChangingDefault && (
                <Field
                  name="shouldSetAsDefault"
                  component={FormCheckboxWithLabel}
                  label="Set as default"
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onClose}
                color="primary"
                data-testid="cancel-button"
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" data-testid="submit-button">
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default SelectBusinessDialog;
