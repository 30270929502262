import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Grid,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { FormTextField } from "../../common/components/form/FormTextField";
import { useAlerts } from "../../common/context/AlertContext";
import { useAuthContext } from "../../common/context/AuthContext";
import { AuthenticationService } from "../../common/service/AuthenticationService";
const LoginPage = () => {
  const { logInAsUser } = useAuthContext();
  const [loginError, setLoginError] = useState("");
  const { addErrorAlert } = useAlerts();

  const handleSubmit = async (values) => {
    setLoginError("");
    return AuthenticationService.login(values.email, values.password)
      .then(logInAsUser)
      .catch((err) => {
        if (err.response?.status === 401) {
          setLoginError(err.response?.data.message);
        } else {
          addErrorAlert("Error completing request", err);
        }
      });
  };

  return (
    <Container maxWidth="xs">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Invalid email")
              .required("Email is required"),
            password: Yup.string().required("Password is required"),
          })}
        >
          {({ values, isSubmitting }) => (
            <Form style={{ width: "100%", marginTop: "24px" }}>
              <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={12}>
                  <Field
                    component={FormTextField}
                    autoFocus
                    name="email"
                    variant="outlined"
                    label="Email"
                    type="email"
                    size="medium"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={FormTextField}
                    name="password"
                    variant="outlined"
                    label="Password"
                    type="password"
                    size="medium"
                    required
                  />
                  <Link
                    to="/forgot-password"
                    state={{ email: values.email }}
                    style={{ float: "right" }}
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Button
                    id="login-button"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress
                          size="small"
                          style={{ height: 16, width: 16 }}
                          color="inherit"
                        />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? "Signing In..." : "Sign In"}
                  </Button>
                </Grid>
                {loginError && (
                  <Grid item xs={12}>
                    <Alert
                      severity="error"
                      sx={{ width: "fit-content", margin: "auto" }}
                    >
                      {loginError}
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
        <Button component={Link} to="/sign-up" sx={{ mt: 3 }}>
          New here? Sign up now
        </Button>
      </div>
    </Container>
  );
};

export default LoginPage;
