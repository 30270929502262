import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

export default function FormSelect({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  options = [],
  label,
  size,
  renderOption,
  ...props
}) {
  const defaultValue = props.multiple ? [] : "";
  const error = touched[name] && Boolean(errors[name]);
  const onChangeHandler = props.onChange || onChange;
  return (
    <FormControl fullWidth size={size || "small"} sx={{ ...props.sx }}>
      <InputLabel id={name + "-label"}>{label}</InputLabel>
      <Select
        labelId={name + "-label"}
        id={name}
        data-testid={name}
        label={label}
        fullWidth
        {...props}
        value={value || defaultValue}
        name={name}
        onChange={(e) => {
          onChangeHandler({ target: { name, value: e.target.value } });
        }}
        onBlur={onBlur}
      >
        {options.map((opt, i) => (
          <MenuItem value={typeof opt === "string" ? opt : opt.value} key={i}>
            {renderOption
              ? renderOption(opt, i)
              : typeof opt === "string"
                ? opt
                : opt.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{errors[name]}</FormHelperText>}
    </FormControl>
  );
}
