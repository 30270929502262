import {
  Autocomplete,
  Avatar,
  CircularProgress,
  ListItemAvatar,
  ListItemText,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useAlerts } from "../../context/AlertContext";
import BaseProductService from "../../service/BaseProductService";
import DebouncedTextField from "./DebouncedTextField";

export default function BaseProductAutocomplete({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  label,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [displayText, setDisplayText] = useState("");
  const [baseProductList, setBaseProductList] = useState();
  const { addErrorAlert } = useAlerts();

  React.useEffect(() => {
    refreshOptions(displayText, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refreshOptions(searchText, selectSingleOption) {
    setLoading(true);
    const searchCriteria = {
      searchText,
      page: 1,
      itemsPerPage: 20,
    };

    BaseProductService.getBaseProducts(searchCriteria)
      .then((response) => {
        const options = response;
        if (
          props.value &&
          !options.find((option) => option.id === props.value.id)
        ) {
          options.push(props.value);
        }
        if (selectSingleOption && options.length === 1 && !props.value) {
          onChange({ target: { name: name, value: options[0] } });
          setDisplayText(options[0].name);
        }
        setBaseProductList(options);
        setLoading(false);
      })
      .catch((error) => {
        addErrorAlert("Error fetching products", error);
      });
  }

  return (
    <Autocomplete
      label={label}
      size="small"
      fullWidth
      id={name}
      data-testid={name}
      loading={loading}
      inputValue={displayText}
      autoHighlight
      onInputChange={(e, newInputValue) => {
        setDisplayText(newInputValue);
      }}
      noOptionsText={
        displayText ? "No products found" : "Start typing to search"
      }
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option) => {
        if (option.id) {
          return option.name;
        } else {
          return option;
        }
      }}
      options={baseProductList || []}
      renderOption={(props, baseProductOption) => {
        if (baseProductOption.id) {
          return (
            <MenuItem {...props} key={baseProductOption.id}>
              <ListItemAvatar>
                <Avatar src={baseProductOption.productPicturePath} />
              </ListItemAvatar>
              <ListItemText primary={baseProductOption.name} />
            </MenuItem>
          );
        }
      }}
      name={name}
      value={value}
      {...props}
      onChange={(e, newValue) => {
        if (!newValue) {
          refreshOptions("", false);
        }
        onChange({ target: { name: name, value: newValue } });
      }}
      renderInput={(params) => (
        <DebouncedTextField
          {...params}
          field={{
            name,
            value,
            onChange: (e) => {
              setDisplayText(e.target.value);
              refreshOptions(e.target.value, false);
            },
            onBlur,
          }}
          form={{ touched, errors }}
          setLoading={setLoading}
          label={label}
          name={name}
          required={props.required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
