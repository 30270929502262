import axios from "axios";

export const STOCK_BASE_URL = process.env.REACT_APP_API_PATH + "/stock";

const StockLogService = {
  async getStockLogs(searchCriteria) {
    const response = await axios.post(
      STOCK_BASE_URL + "/search",
      searchCriteria
    );
    return response.data;
  },
  async getInventory(searchCriteria) {
    const response = await axios.post(
      STOCK_BASE_URL + "/search/presale-inventory",
      searchCriteria
    );
    return response.data;
  },
  async getPointOfSaleInventory(searchCriteria) {
    const response = await axios.post(
      STOCK_BASE_URL + "/search/point-of-sale-inventory",
      searchCriteria
    );
    return response.data;
  },
  async getInventoryForAgeOut(searchCriteria) {
    const response = await axios.post(
      STOCK_BASE_URL + "/search/inventory-for-age-out",
      searchCriteria
    );
    return response.data;
  },
  async addStockLog(stockLog) {
    const response = await axios.post(STOCK_BASE_URL, stockLog);
    return response.data;
  },
  async updateStockLog(stockLog) {
    const response = await axios.put(
      STOCK_BASE_URL + `/${stockLog.id}`,
      stockLog
    );
    return response.data;
  },
  async deleteStockLog(stockLogId) {
    const response = await axios.delete(STOCK_BASE_URL + "/" + stockLogId);
    return response.data;
  },
  async submitProducts(marketDate, supplierId, stockLogIds) {
    const response = await axios.put(STOCK_BASE_URL + "/submit", {
      marketDate,
      supplierId,
      stockLogIds,
    });
    return response.data;
  },
};

export default StockLogService;
