import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Fab,
  Toolbar,
} from "@mui/material";
import React from "react";
import SlideUpTransition from "../animations/SlideUpTransition";

export default function MainContentWithResponsiveDrawer({
  mainContent,
  drawerContent,
  mobileOpenDrawerContent,
}) {
  const [mobileCartOpen, setMobileCartOpen] = React.useState(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          maxHeight: "100%",
        }}
      >
        <Box
          data-testid="main-content"
          sx={{
            flexDirection: "column",
            flexGrow: 1,
            flexShrink: 1,
            display: "flex",
            overflowY: "auto",
            paddingBottom: "150px",
            minWidth: 100,
            maxHeight: "100vh", // Ensure the Box takes the full viewport height
          }}
        >
          {mainContent}
        </Box>
        <Box
          data-testid="responsive-drawer"
          sx={{
            position: "relative",
            display: { xs: "none", sm: "inherit" },
            width: { xs: 0, sm: 280, md: 380 },
            maxWidth: { xs: 0, sm: 280, md: 380 },
            flexShrink: 0,
            height: "100vh",
            overflowY: "auto",
            maxHeight: "100%",
          }}
        >
          {drawerContent}
        </Box>
      </Box>
      <Toolbar
        sx={{
          backgroundColor: "secondary.light",
          display: { xs: "flex", sm: "none" },
          position: "fixed",
          right: 0,
          bottom: 0,
          width: "calc(100% - 56px)",
          m: 0,
          p: 0,
        }}
      >
        <Button
          onClick={() => setMobileCartOpen(true)}
          sx={{
            width: "100%",
            display: "flex",
            margin: "auto",
            alignSelf: "stretch",
            zIndex: 1000000,
          }}
          size="large"
        >
          {mobileOpenDrawerContent}
        </Button>
      </Toolbar>
      <Dialog
        open={mobileCartOpen}
        onClose={() => setMobileCartOpen(false)}
        fullScreen
        TransitionComponent={SlideUpTransition}
      >
        <DialogContent sx={{ position: "relative", p: 0, m: 0 }}>
          <Fab
            sx={{ position: "fixed", right: 16, top: 16 }}
            onClick={() => setMobileCartOpen(false)}
            size="small"
          >
            <Close />
          </Fab>
          {drawerContent}
        </DialogContent>
      </Dialog>
    </>
  );
}
