import { Box, Button, Popover } from "@mui/material";
import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";
import { FormTextField } from "./FormTextField";

export default function FormColorInput({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  label,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverIsOpen = Boolean(anchorEl);
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          p: 1,
          gap: 1,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: 120 }}>
          <FormTextField
            field={{ name, value, onChange, onBlur }}
            form={{ touched, errors }}
            {...props}
            label={label}
            inputProps={{ pattern: "#[0-9A-Fa-f]{6}" }}
          />
        </Box>
        <Button
          sx={{
            width: 28,
            height: 28,
            maxWidth: 28,
            minWidth: 28,
            "&:hover": { bgcolor: value + "CC" },
            borderRadius: 2,
            border: "2px solid #ccc",
            bgcolor: value,
          }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        />

        <Popover
          open={popoverIsOpen}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <Box>
            <HexColorPicker
              format="hex"
              {...props}
              color={value}
              onChange={(newVal) =>
                onChange({ target: { name, value: newVal } })
              }
            />
          </Box>
        </Popover>
      </Box>
    </Box>
  );
}
