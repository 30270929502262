import Grid from "@mui/material/Unstable_Grid2/Grid2.js";
import { Field } from "formik";
import React from "react";
import DebouncedTextField from "../../../common/components/form/DebouncedTextField.jsx";

const UserSearchCriteria = () => {
  return (
    <Grid container sx={{ mb: 1 }} spacing={3} direction="row">
      <Grid>
        <Field
          component={DebouncedTextField}
          label="Search..."
          name="searchText"
        />
      </Grid>
    </Grid>
  );
};

export default UserSearchCriteria;
