import {
  Autocomplete,
  CircularProgress,
  ListItemText,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAlerts } from "../../context/AlertContext";
import PickupLocationService from "../../service/PickupLocationService";
import { displayPrice } from "../../util/DisplayUtil";
import { FormTextField } from "./FormTextField";

export default function PickupLocationAutocomplete({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  label,
  ...props
}) {
  const { addErrorAlert } = useAlerts();
  const [loading, setLoading] = useState(false);
  const [displayText, setDisplayText] = useState("");
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);

  useEffect(() => {
    setLoading(true);
    PickupLocationService.findAll()
      .then((response) => {
        setLocations(response);
        setFilteredLocations(response);
      })
      .catch((error) => {
        addErrorAlert("Error fetching pickup locations", error);
      })
      .finally(() => setLoading(false));
  }, [addErrorAlert]);

  useEffect(() => {
    if (props.value || !displayText) {
      setFilteredLocations(locations);
    } else {
      setFilteredLocations(
        locations.filter((location) =>
          location.name.toLowerCase().includes(displayText.toLowerCase())
        )
      );
    }
  }, [displayText, locations, props.value]);

  return (
    <Autocomplete
      label={label}
      size="small"
      fullWidth
      id={name}
      data-testid={name}
      loading={loading}
      inputValue={displayText}
      autoHighlight
      onInputChange={(e, newInputValue) => {
        setDisplayText(newInputValue);
      }}
      noOptionsText={
        displayText ? "No pickup locations found" : "Start typing to search"
      }
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option) => {
        if (option.id) {
          return `${option.name}${Boolean(option.pickupFee) ? ` (+${displayPrice(option.pickupFee)})` : ""}`;
        } else {
          return option;
        }
      }}
      options={filteredLocations}
      renderOption={(props, locationOption) => {
        if (locationOption.id) {
          return (
            <MenuItem {...props} key={locationOption.id}>
              <ListItemText
                primary={locationOption.name}
                secondary={
                  locationOption.pickupFee
                    ? `+ $${parseFloat(locationOption.pickupFee).toFixed(2)}`
                    : undefined
                }
              />
            </MenuItem>
          );
        }
      }}
      name={name}
      value={value}
      {...props}
      onChange={(e, newValue) => {
        onChange({ target: { name: name, value: newValue } });
      }}
      renderInput={(params) => (
        <FormTextField
          {...params}
          field={{
            name,
            value,
            onChange: (e) => {
              setDisplayText(e.target.value);
            },
            onBlur,
          }}
          form={{ touched, errors }}
          label={label}
          name={name}
          required={props.required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
