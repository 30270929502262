import { Add } from "@mui/icons-material";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import BusinessDisplay from "../../../common/components/data-display/BusinessDisplay.jsx";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults.jsx";
import { ProductInstanceSummary } from "../../../common/components/product/ProductInstanceSummary.jsx";
import ProductQuantityChip from "../../../common/components/product/ProductQuantityChip.jsx";
import ProductSaleFocusDisplay from "../../../common/components/product/ProductSaleFocusDisplay.jsx";
import { AgedOutProvider } from "../../../common/context/AgedOutContext.jsx";
import { useCollective } from "../../../common/context/CollectiveContext.jsx";
import StockLogService from "../../../common/service/StockLogService.js";
import { getNextPrimaryMarketDate } from "../../../common/util/DatePickerUtilFrontend.js";
import UploadInventoryDialog from "../../Supplier/UploadInventory/UploadInventoryDialog.jsx";
import ManageInventorySearchCriteria from "./ManageInventorySearchCriteria.jsx";

function ProductInstanceSummaryAdminInventoryView({ item, ...props }) {
  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <ProductInstanceSummary
        productInstance={item}
        {...props}
        sx={{ maxWidth: 800 }}
        summaryComponents={[
          ({ product }) => (
            <BusinessDisplay sx={{ width: 200 }} business={product.supplier} />
          ),
          ProductSaleFocusDisplay,
          ProductQuantityChip,
        ]}
      />
    </Box>
  );
}

const ManageInventory = () => {
  const { collectiveInfo } = useCollective();
  const initialDateStocked = getNextPrimaryMarketDate(collectiveInfo);
  const defaultSearchCriteria = {
    dateStocked: initialDateStocked.format("YYYY-MM-DD"),
    status: "SUBMITTED",
    orderBy: "BringToMarket",
    businesses: [],
    saleFocus: "",
    page: 1,
    itemsPerPage: 10,
  };
  const [selectedStockProductInstance, setSelectedStockProductInstance] =
    React.useState();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const searchRef = useRef();
  return (
    <Container sx={{ pt: 1.5 }}>
      <AgedOutProvider>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item>
            <Typography variant="h2" gutterBottom>
              Manage Inventory
            </Typography>
          </Grid>
          <Grid item>
            <Link to="add">
              <Button variant="contained" color="primary" startIcon={<Add />}>
                Add Inventory
              </Button>
            </Link>
          </Grid>
        </Grid>
        <PaginatedSearchResults
          ref={searchRef}
          fetchSearchResults={StockLogService.getStockLogs}
          ResultDisplayComponent={ProductInstanceSummaryAdminInventoryView}
          SearchCriteriaComponent={ManageInventorySearchCriteria}
          defaultSearchCriteria={defaultSearchCriteria}
          onClickSearchResult={(productInstance) => {
            setSelectedStockProductInstance(productInstance);
            setDialogOpen(true);
          }}
          validationSchema={Yup.object().shape({
            dateStocked: Yup.date()
              .required()
              .nullable()
              .typeError("Valid date required"),
          })}
          normalizeSearchCriteria={(criteria) => {
            if (
              criteria.dateStocked &&
              typeof criteria.dateStocked !== "string"
            ) {
              criteria.dateStocked = criteria.dateStocked.format("YYYY-MM-DD");
            }

            return criteria;
          }}
        />

        <UploadInventoryDialog
          productInstance={selectedStockProductInstance}
          isOpen={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
          }}
          onUpdate={() => {
            searchRef.current.rerunSearch();
            setDialogOpen(false);
          }}
          onDelete={() => {
            searchRef.current.rerunSearch();
            setDialogOpen(false);
          }}
        />
      </AgedOutProvider>
    </Container>
  );
};

export default ManageInventory;
