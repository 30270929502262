import { Box, Typography } from "@mui/material";
import { Field } from "formik";
import React from "react";
import {
  FLOWER_QUALITIES,
  FLOWER_QUALITIES_NO_BASIC,
} from "../../../pages/Supplier/UploadInventory/UploadInventoryDialog";
import { displayPrice } from "../../util/DisplayUtil";
import FormSelect from "./FormSelect";

function getLengthTextForQuality(quality, productDetails) {
  let lengthAttrName = "";
  if (quality === "Basic") {
    lengthAttrName = "Length Basic";
  } else if (quality === "Standard") {
    lengthAttrName = "Length Standard";
  } else if (quality === "Premium") {
    lengthAttrName = "Length Premium";
  }
  const lengthText = productDetails.attributes.find(
    (attr) => attr.attributeName === lengthAttrName
  )?.value;
  return lengthText || "";
}

export default function FlowerQualitySelect({
  productDetails,
  disabled,
  name,
  includeBasicQuality = true,
  ...props
}) {
  const defaultOptionsList = includeBasicQuality
    ? FLOWER_QUALITIES
    : FLOWER_QUALITIES_NO_BASIC;
  const [qualityOptions, setQualityOptions] =
    React.useState(defaultOptionsList);

  React.useEffect(() => {
    const { priceGroups } = productDetails;

    const isQualityUsedForPrice = priceGroups?.some((priceGroup) =>
      priceGroup.instanceAttributes.some(
        (attr) => attr.attributeName === "Quality"
      )
    );
    if (isQualityUsedForPrice) {
      // If quality is used to determine price, show the quality select with the price added
      let qualityOptionsWithPrice = defaultOptionsList.filter((quality) => {
        return priceGroups.some((priceGroup) =>
          priceGroup.instanceAttributes.some(
            (attr) =>
              attr.attributeName === "Quality" &&
              attr.attributeValue === quality
          )
        );
      });
      qualityOptionsWithPrice = qualityOptionsWithPrice.map((quality) => {
        const priceGroupForQuality = priceGroups.find((priceGroup) =>
          priceGroup.instanceAttributes.some(
            (attr) =>
              attr.attributeName === "Quality" &&
              attr.attributeValue === quality
          )
        );
        const price = priceGroupForQuality.price;
        const lengthText = getLengthTextForQuality(quality, productDetails);

        return {
          label: (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {" "}
                <Typography>{quality}</Typography>{" "}
                <Typography variant="caption">
                  {lengthText && `Stem Length ${lengthText}`}
                </Typography>{" "}
              </Box>
              <Typography>{displayPrice(price)}</Typography>
            </Box>
          ),
          value: quality,
        };
      });
      setQualityOptions(qualityOptionsWithPrice);
    } else {
      // If quality is not used to determine price, show the quality select without the price
      setQualityOptions(defaultOptionsList);
    }
  }, [defaultOptionsList, productDetails]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Field
        component={FormSelect}
        name={name}
        label="Quality"
        options={qualityOptions}
        disabled={disabled}
      />
    </Box>
  );
}
