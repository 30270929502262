import { FormTextField } from "./FormTextField";

export function FormQuantityField(props) {
  return (
    <FormTextField
      type="number"
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
        min: 1,
        step: 1,
      }}
      {...props}
    />
  );
}
