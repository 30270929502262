import axios from "axios";

export const FINANCE_REPORTING_BASE_URL =
  process.env.REACT_APP_API_PATH + "/financialReporting";

const FinancialReportingService = {
  async getSupplierSnapshotTotals(searchCriteria) {
    const response = await axios.post(
      FINANCE_REPORTING_BASE_URL + "/supplier-snapshot-totals",
      searchCriteria
    );
    return response.data;
  },
  async getSupplierSalesTotals(startDate, endDate) {
    const params = new URLSearchParams();
    if (startDate) {
      params.append("startDate", startDate);
    }
    if (endDate) {
      params.append("endDate", endDate);
    }
    const response = await axios.get(
      FINANCE_REPORTING_BASE_URL + "/supplier-totals",
      {
        params,
      }
    );
    return response.data;
  },
};

export default FinancialReportingService;
