export function displayName(first, last) {
  if (first && last) {
    return `${first} ${last}`;
  } else {
    return first || last || "";
  }
}

export function displayPercentage(decimalPercentage) {
  if (decimalPercentage === undefined || decimalPercentage === null) {
    return "--";
  }
  return `${(decimalPercentage * 100).toFixed(0)}%`;
}

export function displayPrice(price) {
  if (price === undefined || price === null) {
    return "--";
  }
  // Round to nearest penny
  price = Math.round(price * 100) / 100;
  return `$${price.toFixed(2)}`;
}

export function displayAdvancedRequestName(advancedRequest) {
  return advancedRequest?.id
    ? advancedRequest.orderReference || `AO${advancedRequest.id}`
    : "New Request";
}
