import { Yard } from "@mui/icons-material";
import {
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import BusinessDisplay from "../../common/components/data-display/BusinessDisplay";
import { getInstanceAttributesText } from "../../common/components/product/ProductInstanceSummary";
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function PointOfSaleInventoryProductSummary({
  item,
  onClick,
  ...props
}) {
  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: 220,
        textAlign: "center",
        alignItems: "start",
        justifyContent: "start",
      }}
      data-testid="inventory-item-summary"
      {...props}
    >
      <CardActionArea
        onClick={onClick}
        sx={{
          height: "100%",
          width: "100%",
          justifyContent: "start",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {item.productPicturePath && (
          <CardMedia
            sx={{ height: 80, width: "100%" }}
            image={item.productPicturePath || <Yard />}
            title={item.name}
          />
        )}
        {!item.productPicturePath && (
          <Yard
            sx={{
              height: 80,
              width: 160,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        )}
        <Grid container sx={{ p: 0.5 }} spacing={1}>
          <Grid
            item
            container
            spacing={0.5}
            fontSize="130%"
            flexWrap="nowrap"
            flexDirection={"column"}
          >
            <Grid item>
              <Typography
                sx={{
                  textWrap: "wrap",
                  fontWeight: "bold",
                }}
                fontSize="115%"
              >
                {item.name}
              </Typography>
            </Grid>
            <Grid item>{getInstanceAttributesText(item.attributes)}</Grid>
          </Grid>

          <Grid
            item
            container
            spacing={1}
            justifyContent="space-between"
            flexWrap={"nowrap"}
          >
            <Grid item flexShrink={0}>
              <BusinessDisplay business={item.supplier} />
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}
