import { Skeleton } from "@mui/material";
import moment from "moment";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAlerts } from "../../common/context/AlertContext";
import SalesLogService from "../../common/service/SalesLogService";
import ProductQuantitySelectDialog from "../Buyer/Presale/ProductQuantitySelectDialog";

const PointOfSaleContext = createContext();

export const PointOfSaleContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [salesLog, setSalesLog] = useState(null);
  const [loading, setLoading] = useState(true);
  const { addErrorAlert } = useAlerts();

  const [itemToAdd, setItemToAdd] = useState(null);
  const [cartItemToEdit, setCartItemToEdit] = useState(null);
  const [formErrors, setFormErrors] = useState(null);
  const [addToCartDialogOpen, setAddToCartDialogOpen] = useState(false);
  const [editCartItemDialogOpen, setEditCartItemDialogOpen] = useState(false);
  const [inventoryChangedFlag, setInventoryChangedFlag] = useState(0);
  const pointOfSaleSearchRef = React.useRef();
  const [isShoppingInventory, setIsShoppingInventory] = React.useState(false);
  const [backToPresaleOrder, setBackToPresaleOrder] = useState(null);
  const [backToAdvancedRequest, setBackToAdvancedRequest] = useState(null);
  const [isShoppingOther, setIsShoppingOther] = useState(false);
  let isNew = false;
  let { salesLogId } = useParams();
  if (salesLogId) {
    salesLogId = parseInt(salesLogId);
  } else {
    isNew = true;
  }

  useEffect(() => {
    if (isNew) {
      const defaultSalesLog = {
        invoiceNumber: null,
        payment: null,
        buyerBusiness: null,
        saleDate: moment().format("YYYY-MM-DD"),
        notes: "",
        pickupLocation: null,
        saleType: "WHOLESALE",
        subtotal: 0,
        total: 0,
        status: "DRAFT",
        items: [],
        presaleOrders: [],
        advancedRequests: [],
      };
      SalesLogService.createSalesLog(defaultSalesLog)
        .then((newSalesLog) => {
          setSalesLog(newSalesLog);
          navigate(`/app/point-of-sale/${newSalesLog.id}`, { replace: true });
        })
        .catch((e) => addErrorAlert("Error creating new sales log", e))
        .finally(() => setLoading(false));
    } else if (salesLogId && salesLog?.id !== salesLogId) {
      SalesLogService.getSalesLogById(salesLogId)
        .then(setSalesLog)
        .catch((err) => addErrorAlert("Error fetching sales log", err))
        .finally(() => setLoading(false));
    } else if (!isNew && !salesLogId) {
      setSalesLog(null);
      setLoading(false);
    }
  }, [addErrorAlert, isNew, navigate, salesLog?.id, salesLogId]);

  function openExistingSalesLog(salesLog) {
    if (salesLog?.id) {
      navigate(`/app/point-of-sale/${salesLog.id}`, { replace: true });
    }
  }

  return (
    <PointOfSaleContext.Provider
      value={{
        salesLog,
        setSalesLog,
        setItemToAdd: (item) => {
          setItemToAdd(item);
          setAddToCartDialogOpen(true);
        },
        setCartItemToEdit: (item) => {
          setCartItemToEdit(item);
          setEditCartItemDialogOpen(true);
        },
        formErrors,
        setFormErrors,
        openExistingSalesLog,
        inventoryChangedFlag,
        setInventoryChangedFlag,
        pointOfSaleSearchRef,
        isShoppingInventory,
        setIsShoppingInventory,
        isShoppingOther,
        setIsShoppingOther,
        backToPresaleOrder,
        setBackToPresaleOrder,
        backToAdvancedRequest,
        setBackToAdvancedRequest,
      }}
    >
      {isNew || loading ? (
        <Skeleton variant="rectangular" height={400} />
      ) : (
        salesLog && children
      )}
      <ProductQuantitySelectDialog
        productInstance={itemToAdd}
        open={addToCartDialogOpen}
        onClose={() => setAddToCartDialogOpen(false)}
        isEditing={false}
        onAddProduct={(productInstance) => {
          return SalesLogService.addSaleItem(salesLog.id, productInstance)
            .then(setSalesLog)
            .then(() => {
              setInventoryChangedFlag(inventoryChangedFlag + 1);
              setAddToCartDialogOpen(false);
            })
            .catch((error) => {
              addErrorAlert("Error adding product to cart", error);
            });
        }}
        allowEditingPrice
        allowOversell
        allowEditingSupplier
        showSupplier
      />
      <ProductQuantitySelectDialog
        productInstance={cartItemToEdit}
        open={editCartItemDialogOpen}
        onClose={() => setEditCartItemDialogOpen(false)}
        isEditing={true}
        onEditQuantity={(productToUpdate) => {
          return SalesLogService.updateSaleItem(salesLog.id, productToUpdate)
            .then(setSalesLog)
            .then(() => {
              setInventoryChangedFlag(inventoryChangedFlag + 1);
              setEditCartItemDialogOpen(false);
            })
            .catch((error) => {
              addErrorAlert("Error updating product in cart", error);
            });
        }}
        allowEditingPrice
        allowOversell
        allowEditingSupplier
        showSupplier
      />
    </PointOfSaleContext.Provider>
  );
};

export const usePointOfSaleContext = () => useContext(PointOfSaleContext);
