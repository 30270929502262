import { Storefront } from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import TypographyEllipsis from "./TypographyEllipsis";

export default function BusinessDisplay({
  business,
  label = "",
  isLink = false,
  sx,
  ...props
}) {
  const linkProps =
    isLink && business?.name
      ? {
          component: Link,
          to: "/app/business-admin/manage-businesses/" + business.id,
        }
      : {};
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        width: "100%",
        ...sx,
      }}
      {...props}
    >
      {business?.name && (
        <>
          <Avatar src={business.logoPath} sx={{ height: 36, width: 36 }}>
            <Storefront />
          </Avatar>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              flexGrow: 1,
              minWidth: 0,
            }}
          >
            {label && <Typography variant="caption">{label}</Typography>}

            <TypographyEllipsis
              variant="body1"
              {...linkProps}
              data-testid="business-display"
            >
              {business.name}
            </TypographyEllipsis>
          </Box>
        </>
      )}
    </Box>
  );
}
