import { Container } from "@mui/material";
import moment from "moment";
import { useRef, useState } from "react";
import * as Yup from "yup";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults";
import { ProductInstanceSummary } from "../../../common/components/product/ProductInstanceSummary";
import ProductMarketDateDisplay from "../../../common/components/product/ProductMarketDateDisplay";
import ProductQuantityWithFulfillmentChip from "../../../common/components/product/ProductQuantityWithFulfillmentChip";
import AdvancedRequestService from "../../../common/service/AdvancedRequestService";
import EditFulfillProductDialog from "./EditFulfillProductDialog";
import FulfillProductDialog from "./FulfillProductDialog";
import SupplierViewProductRequestSearchCriteria from "./SupplierViewProductRequestSearchCriteria";

function ProductInstanceSummarySupplierView({ item, ...props }) {
  return (
    <ProductInstanceSummary
      productInstance={item}
      {...props}
      summaryComponents={[
        ProductMarketDateDisplay,
        ProductQuantityWithFulfillmentChip,
      ]}
    />
  );
}

export default function AdvancedRequestSupplierView() {
  const defaultSearchCriteria = {
    searchText: "",
    pickupDate: null,
    showOnlyUnfulfilled: true,
    myCommitmentsOnly: false,
    page: 1,
    itemsPerPage: 10,
  };
  const [selectedProductInstance, setSelectedProductInstance] = useState();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const searchRef = useRef();

  function onSelectProductInstance(productInstance) {
    if (productInstance.quantityFulfilledByUser > 0) {
      setEditDialogOpen(true);
    } else {
      setAddDialogOpen(true);
    }
    setSelectedProductInstance(productInstance);
  }

  return (
    <Container
      data-testid="supplier-view-requested-products-page"
      sx={{ maxWidth: 800 }}
    >
      <PaginatedSearchResults
        ref={searchRef}
        fetchSearchResults={
          AdvancedRequestService.getProductsForUpcomingRequests
        }
        defaultSearchCriteria={defaultSearchCriteria}
        SearchCriteriaComponent={SupplierViewProductRequestSearchCriteria}
        data-testid="paginated-search-results"
        ResultDisplayComponent={ProductInstanceSummarySupplierView}
        onClickSearchResult={onSelectProductInstance}
        validationSchema={Yup.object().shape({
          searchText: Yup.string(),
          pickupDate: Yup.mixed()
            .nullable()
            .test(
              "is-future",
              "Pickup date must be today or in the future",
              (date) => !date || !moment(date).isBefore(moment().startOf("day"))
            ),
        })}
        normalizeSearchCriteria={(criteria) => {
          // Remove any values that are falsey
          const cleanedCriteria = Object.fromEntries(
            Object.entries(criteria).filter(([_, v]) => v)
          );
          if (
            cleanedCriteria.pickupDate &&
            typeof cleanedCriteria.pickupDate !== "string"
          ) {
            cleanedCriteria.pickupDate =
              cleanedCriteria.pickupDate.format("YYYY-MM-DD");
          }

          return cleanedCriteria;
        }}
      />
      <FulfillProductDialog
        product={selectedProductInstance}
        open={Boolean(addDialogOpen)}
        onAddFullfillment={() => {
          searchRef.current?.rerunSearch();
          setAddDialogOpen(false);
        }}
        onClose={() => setAddDialogOpen(false)}
      />
      <EditFulfillProductDialog
        product={selectedProductInstance}
        open={Boolean(editDialogOpen)}
        onEditFullfillment={() => {
          searchRef.current?.rerunSearch();
          setEditDialogOpen(false);
        }}
        onClose={() => setEditDialogOpen(false)}
      />
    </Container>
  );
}
