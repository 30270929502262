import { CheckCircle, NotInterested, SwapHoriz } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Field, Formik } from "formik";
import React from "react";
import { Form } from "react-router-dom";
import AutoSubmitOnChange from "../../common/components/form/AutoSubmitOnChange";
import FormSelect from "../../common/components/form/FormSelect";
import { useAlerts } from "../../common/context/AlertContext";

export default function ProductSalesCheckoutStatusForm({
  product,
  updateSalesCheckoutStatus,
}) {
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  return (
    <Formik
      initialValues={{
        id: product.id,
        salesCheckoutStatus: product.salesCheckoutStatus,
      }}
      onSubmit={async (values) => {
        return updateSalesCheckoutStatus(values.id, values.salesCheckoutStatus)
          .then(() => {
            addSuccessAlert("Status updated");
          })
          .catch((e) => {
            addErrorAlert("Error changing payment info", e);
          });
      }}
    >
      {({ handleChange, handleSubmit }) => (
        <Form autoComplete="off">
          <AutoSubmitOnChange />
          <Field
            component={FormSelect}
            name="salesCheckoutStatus"
            label="Status"
            options={[
              { value: "", label: "--Select an Option--" },
              { value: "Fulfilled", label: "Fulfilled" },
              {
                value: "Not Available",
                label: "Not Available",
              },
              { value: "Substituted", label: "Substituted" },
            ]}
            onChange={(e) => {
              handleChange(e);
              handleSubmit();
            }}
            renderOption={(opt) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                {opt.value === "Substituted" && <SwapHoriz color="warning" />}
                {opt.value === "Fulfilled" && <CheckCircle color="success" />}
                {opt.value === "Not Available" && (
                  <NotInterested color="error" />
                )}
                <Typography>{opt.label}</Typography>
              </Box>
            )}
            renderValue={(val) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                {val === "Substituted" && <SwapHoriz color="warning" />}
                {val === "Fulfilled" && <CheckCircle color="success" />}
                {val === "Not Available" && <NotInterested color="error" />}
                <Typography>{val}</Typography>
              </Box>
            )}
            sx={{ width: "180px" }}
          />
        </Form>
      )}
    </Formik>
  );
}
