import { Container } from "@mui/material";
import * as Yup from "yup";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults";
import AdvancedRequestService from "../../../common/service/AdvancedRequestService";
import { AdminAdvancedRequestSummary } from "../../AdvancedRequests/AdvancedRequestSummary";
import AdvancedRequestSearchCriteria from "./AdvancedRequestSearchCriteria";

export default function ManageAdvancedRequests() {
  const defaultSearchCriteria = {
    searchText: "",
    page: 1,
    itemsPerPage: 10,
    orderBy: "pickupDateDesc",
    startDateRange: null,
    endDateRange: null,
    businesses: [],
  };
  return (
    <Container
      data-testid="manage-advanced-requests-page"
      sx={{ maxWidth: 800 }}
    >
      <PaginatedSearchResults
        fetchSearchResults={AdvancedRequestService.getAdvancedRequests}
        defaultSearchCriteria={defaultSearchCriteria}
        SearchCriteriaComponent={AdvancedRequestSearchCriteria}
        data-testid="paginated-search-results"
        ResultDisplayComponent={AdminAdvancedRequestSummary}
        validationSchema={Yup.object().shape({
          searchText: Yup.string(),
          // Ensure startDateRange is before endDateRange
          startDateRange: Yup.date()
            .nullable()
            .when("endDateRange", {
              is: (endDateRange) => endDateRange,
              then: () =>
                Yup.date().max(
                  Yup.ref("endDateRange"),
                  "Start date must be before end date"
                ),
            }),
          endDateRange: Yup.date().nullable(),
          businesses: Yup.array(),
        })}
        normalizeSearchCriteria={(criteria) => {
          // Remove any values that are falsey
          const cleanedCriteria = Object.fromEntries(
            Object.entries(criteria).filter(([_, v]) => v)
          );
          if (
            cleanedCriteria.startDateRange &&
            typeof cleanedCriteria.startDateRange !== "string"
          ) {
            cleanedCriteria.startDateRange =
              cleanedCriteria.startDateRange.toISOString();
          }
          if (
            cleanedCriteria.endDateRange &&
            typeof cleanedCriteria.endDateRange !== "string"
          ) {
            cleanedCriteria.endDateRange =
              cleanedCriteria.endDateRange.toISOString();
          }
          return cleanedCriteria;
        }}
      />
    </Container>
  );
}
