import {
  Autocomplete,
  Avatar,
  CircularProgress,
  ListItemAvatar,
  ListItemText,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useAlerts } from "../../context/AlertContext";
import UserService from "../../service/UserService";
import { displayName } from "../../util/DisplayUtil";
import DebouncedTextField from "./DebouncedTextField";

export default function UserAutocomplete({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  label,
  onAddNewUser,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [displayText, setDisplayText] = useState("");
  const [userList, setUserList] = useState();
  const { addErrorAlert } = useAlerts();

  React.useEffect(() => {
    refreshOptions(displayText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refreshOptions(searchText) {
    setLoading(true);
    UserService.getUsers({ searchText, page: 1, itemsPerPage: 20 })
      .then((response) => {
        setUserList(response);
        setLoading(false);
      })
      .catch((error) => {
        addErrorAlert("Error fetching users", error);
      });
  }

  return (
    <>
      <Autocomplete
        label={label}
        size="small"
        fullWidth
        id={name}
        data-testid={name}
        loading={loading}
        inputValue={displayText}
        onInputChange={(e, newInputValue) => {
          setDisplayText(newInputValue);
        }}
        noOptionsText={
          displayText ? "No users found" : "Start typing to search"
        }
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        getOptionLabel={(option) => {
          if (option.id) {
            return displayName(option.firstName, option.lastName);
          } else {
            return option;
          }
        }}
        options={userList || []}
        filterOptions={(x) => {
          if (!displayText) {
            return x;
          } else {
            return [...x, "Add user " + displayText];
          }
        }}
        renderOption={(props, userOption) => {
          if (userOption.id) {
            return (
              <MenuItem {...props} key={userOption.id}>
                <ListItemAvatar>
                  <Avatar src={userOption.profilePicPath} />
                </ListItemAvatar>
                <ListItemText
                  primary={displayName(
                    userOption.firstName,
                    userOption.lastName
                  )}
                  secondary={userOption.email}
                />
              </MenuItem>
            );
          } else {
            return (
              <MenuItem {...props} key={userOption}>
                <ListItemText primary={userOption} />
              </MenuItem>
            );
          }
        }}
        name={name}
        value={value}
        {...props}
        onChange={(e, newValue) => {
          if (typeof newValue === "string") {
            onChange({ target: { name, value: null } });
            return onAddNewUser(displayText);
          }
          onChange({ target: { name, value: newValue } });
        }}
        renderInput={(params) => (
          <DebouncedTextField
            {...params}
            field={{
              name,
              value,
              onChange: (e) => {
                setDisplayText(e.target.value);
                refreshOptions(e.target.value, false);
              },
              onBlur,
            }}
            form={{ touched, errors }}
            setLoading={setLoading}
            required={props.required}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
}
