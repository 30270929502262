import { ArrowBack } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import moment from "moment";
import React from "react";
import PaginatedSearchResults from "../../common/components/data-display/PaginatedSearchResults";
import StockLogService from "../../common/service/StockLogService";
import { usePointOfSaleContext } from "./PointOfSaleContextProvider";
import PointOfSaleInventoryProductSummary from "./PointOfSaleInventoryProductSummary";
import PointOfSaleInventorySearchCriteria from "./PointOfSaleInventorySearchCriteria";

export default function PointOfSaleInventorySearch() {
  const {
    setItemToAdd,
    pointOfSaleSearchRef,
    setIsShoppingInventory,
    backToPresaleOrder,
    backToAdvancedRequest,
  } = usePointOfSaleContext();
  const defaultSearchCriteria = {
    searchText: "",
    colors: [],
    startDate: moment().add(-10, "days").format("YYYY-MM-DD"),
    endDate: moment().add(1, "day").startOf("day").format("YYYY-MM-DD"),
    supplier: null,
    page: 1,
    specificProductInstance: null,
    itemsPerPage: 24,
  };

  return (
    <Box data-testid="sales-inventory" flexDirection="column">
      <Box justifyContent="space-between">
        <Button
          size="large"
          onClick={() => setIsShoppingInventory(false)}
          startIcon={<ArrowBack />}
          variant="contained"
          sx={{ mt: 1, ml: 1 }}
        >
          Back to Menu
        </Button>
        {backToPresaleOrder && (
          <Button
            size="large"
            onClick={backToPresaleOrder}
            startIcon={<ArrowBack />}
            variant="contained"
            sx={{ mt: 1, ml: 1 }}
          >
            Back to Presale Order
          </Button>
        )}
        {backToAdvancedRequest && (
          <Button
            size="large"
            onClick={backToAdvancedRequest}
            startIcon={<ArrowBack />}
            variant="contained"
            sx={{ mt: 1, ml: 1 }}
          >
            Back to Product Request
          </Button>
        )}
      </Box>
      <PaginatedSearchResults
        ref={pointOfSaleSearchRef}
        navigateOnSearch={false}
        SearchCriteriaComponent={PointOfSaleInventorySearchCriteria}
        fetchSearchResults={StockLogService.getPointOfSaleInventory}
        defaultSearchCriteria={defaultSearchCriteria}
        ResultDisplayComponent={PointOfSaleInventoryProductSummary}
        SearchResultsProps={{
          sx: {
            display: "flex",
            gap: 2.5,
            flexWrap: "wrap",
            justifyContent: "space-around",
          },
        }}
        PaginationProps={{
          itemsPerPageOptions: [
            { label: "24", value: 24 },
            { label: "48", value: 48 },
            { label: "100", value: 100 },
          ],
        }}
        onClickSearchResult={(item) => {
          setItemToAdd(item);
        }}
      />
    </Box>
  );
}
