import { Download, Edit, Email } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { Link, useParams } from "react-router-dom";
import BusinessDisplay from "../../../common/components/data-display/BusinessDisplay";
import PaymentDisplay from "../../../common/components/data-display/PaymentDisplay";
import UserDisplay from "../../../common/components/data-display/UserDisplay";
import { ProductInstanceSummary } from "../../../common/components/product/ProductInstanceSummary";
import ProductPriceDisplay from "../../../common/components/product/ProductPriceDisplay";
import ProductQuantityChip from "../../../common/components/product/ProductQuantityChip";
import { useAlerts } from "../../../common/context/AlertContext";
import { useAuthContext } from "../../../common/context/AuthContext";
import SalesLogService from "../../../common/service/SalesLogService";
import SalesLogCostSummary from "../../PointOfSale/SalesLogCostSummary";
import { useSalesLogContext } from "./SalesLogContext";

export default function SalesLogDetails({ adminView = false }) {
  const { salesLogId } = useParams();
  const { salesLog } = useSalesLogContext(salesLogId);
  const { isMemberOfCollective } = useAuthContext();
  const [sendingEmail, setSendingEmail] = React.useState(false);
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  if (!salesLog) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  const adminSummaryComponents = [
    // Only show the supplier logo and name if in admin view
    ({ product }) => (
      <BusinessDisplay sx={{ maxWidth: 150 }} business={product.supplier} />
    ),
    ProductQuantityChip,
    ProductPriceDisplay,
  ];

  const nonAdminSummaryComponents = [
    // Only show the supplier logo and name if in admin view
    ProductQuantityChip,
    ProductPriceDisplay,
  ];

  return (
    <Container sx={{ pt: 1.5 }}>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" data-testid="sales-log-title">
          Sales Log - #{salesLog.invoiceNumber}
        </Typography>
        <Button
          variant="outlined"
          LinkComponent={Link}
          to={`/app/point-of-sale/${salesLog.id}`}
          startIcon={<Edit />}
        >
          Edit Sale Details
        </Button>
      </Box>
      <Divider sx={{ m: 2 }} />
      <Grid container spacing={2} alignItems="start">
        <Grid
          item
          xs={12}
          md={4}
          container
          spacing={2.5}
          alignItems="start"
          justifyContent="start"
        >
          <Grid item xs={12} container alignItems="center">
            <Grid item sx={{ width: 80, minWidth: 80 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Sale Date
              </Typography>
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <Typography variant="body1" data-testid="sale-date">
                {moment(salesLog.saleDate).format("LL")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            sx={{ flexWrap: "nowrap" }}
          >
            <Grid item sx={{ width: 80, minWidth: 80 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Buyer
              </Typography>
            </Grid>
            <Grid
              item
              sx={{ flexGrow: 1, overflow: "hidden" }}
              data-testid="sales-log-buyer"
            >
              <BusinessDisplay business={salesLog.buyerBusiness} isLink />
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Grid item sx={{ width: 80, minWidth: 80 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Cashier
              </Typography>
            </Grid>
            <Grid
              item
              sx={{ flexGrow: 1, overflow: "hidden" }}
              data-testid="sales-log-cashier"
            >
              <UserDisplay user={salesLog.createdBy} />
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Grid item sx={{ width: 80, minWidth: 80 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Payment
              </Typography>
            </Grid>
            <Grid
              item
              sx={{ flexGrow: 1, overflow: "hidden" }}
              data-testid="sales-log-payment"
            >
              <PaymentDisplay paymentMethod={salesLog.payment?.paymentMethod} />
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Grid item sx={{ width: 80, minWidth: 80 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Pickup
              </Typography>
            </Grid>
            <Grid
              item
              sx={{ flexGrow: 1, overflow: "hidden" }}
              data-testid="sales-log-pickup"
            >
              {salesLog.pickupLocation ? (
                salesLog.pickupLocation.name
              ) : (
                <Typography variant="body1">Unknown</Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} container flexWrap="nowrap">
            <SalesLogCostSummary salesLog={salesLog} />
          </Grid>
          {isMemberOfCollective && (
            <Grid item xs={12} container gap={2}>
              <Button
                startIcon={
                  sendingEmail ? <CircularProgress size={24} /> : <Email />
                }
                onClick={() => {
                  setSendingEmail(true);
                  SalesLogService.resendReceipt(salesLog.id)
                    .then(() => addSuccessAlert("Receipt sent"))
                    .catch((e) => addErrorAlert("Failed to send receipt", e))
                    .finally(() => setSendingEmail(false));
                }}
                disabled={sendingEmail}
                variant="outlined"
              >
                Resend Receipt
              </Button>
              <Button
                startIcon={
                  sendingEmail ? <CircularProgress size={24} /> : <Download />
                }
                onClick={() => {
                  setSendingEmail(true);
                  SalesLogService.downloadReceipt(salesLog.id)
                    .then((resp) => {
                      const url = window.URL.createObjectURL(new Blob([resp]));
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute(
                        "download",
                        `receipt-${salesLog.id}.pdf`
                      );
                      document.body.appendChild(link);
                      link.click();

                      addSuccessAlert("Download started");
                    })
                    .catch((e) =>
                      addErrorAlert("Failed to download receipt", e)
                    )
                    .finally(() => setSendingEmail(false));
                }}
                disabled={sendingEmail}
                variant="outlined"
                color="success"
              >
                Download Receipt
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          container
          spacing={0}
          data-testid="sales-log-items"
          sx={{ mt: 1 }}
          xs={12}
          md={8}
        >
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Sale Items
            </Typography>
          </Grid>
          {salesLog.items.map((item, index) => (
            <ProductInstanceSummary
              key={index}
              productInstance={item}
              summaryComponents={
                adminView ? adminSummaryComponents : nonAdminSummaryComponents
              }
              hideOverflow={false}
            />
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}
