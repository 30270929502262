import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import { Field, Form, Formik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { FormTextField } from "../../common/components/form/FormTextField";
import { useAuthContext } from "../../common/context/AuthContext";
import { AuthenticationService } from "../../common/service/AuthenticationService";
import NewPasswordRequirements from "./NewPasswordRequirements";
const SignUpPage = () => {
  const { logInAsUser } = useAuthContext();
  const [signupError, setSignUpError] = useState("");

  const handleSubmit = async (values) => {
    setSignUpError("");
    return AuthenticationService.signup(values.email, values.password)
      .then(logInAsUser)
      .catch((err) => {
        if (
          err.response?.status === 401 ||
          err.response?.status === 400 ||
          err.response?.status === 409
        ) {
          setSignUpError(err.response?.data.message);
        }
      });
  };

  return (
    <Container maxWidth="xs">
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Invalid email")
            .required("Email is required"),
          password: Yup.string().required("Password is required"),
        })}
      >
        {({ values, isSubmitting }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" gutterBottom>
              New User
            </Typography>
            <Form style={{ width: "100%", marginTop: "24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={FormTextField}
                    autoFocus
                    name="email"
                    fullWidth
                    variant="outlined"
                    type="email"
                    label="Email"
                    size="medium"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={FormTextField}
                    name="password"
                    fullWidth
                    variant="outlined"
                    label="Password"
                    type="password"
                    size="medium"
                    autoComplete="new-password"
                  />
                </Grid>
              </Grid>
              <NewPasswordRequirements passwordToTest={values.password} />

              <Button
                id="signup-button"
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isSubmitting}
                startIcon={
                  isSubmitting ? (
                    <CircularProgress
                      size="small"
                      style={{ height: 16, width: 16 }}
                      color="inherit"
                    />
                  ) : undefined
                }
              >
                {isSubmitting ? "Signing Up..." : "Sign Up"}
              </Button>
              {signupError && (
                <Alert
                  severity="error"
                  sx={{ m: 1, width: "fit-content", ml: "auto", mr: "auto" }}
                >
                  {signupError}
                </Alert>
              )}
            </Form>
            <Button component={Link} to="/login">
              Already signed up? Log in here
            </Button>
          </div>
        )}
      </Formik>
    </Container>
  );
};

export default SignUpPage;
