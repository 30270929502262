// This component allows for the user to edit: Sale Date, Buyer (business), and the Pickup Location
import { Box, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import AutoSubmitOnChange from "../../common/components/form/AutoSubmitOnChange";
import BusinessAutocomplete from "../../common/components/form/BusinessAutocomplete";
import { FormDatePicker } from "../../common/components/form/FormDatePicker";
import PickupLocationAutocomplete from "../../common/components/form/PickupLocationAutocomplete";
import { useAlerts } from "../../common/context/AlertContext";
import SalesLogService from "../../common/service/SalesLogService";
import { usePointOfSaleContext } from "./PointOfSaleContextProvider";

const validationSchema = Yup.object({
  saleDate: Yup.date()
    .typeError("Invalid date")
    .nullable()
    .required("Sale Date is required"),
  buyerBusienss: Yup.mixed().nullable(),
  pickupLocation: Yup.mixed().nullable(),
});

export default function PointOfSaleBaseDetailsForm() {
  const { addErrorAlert } = useAlerts();
  const { salesLog, setSalesLog } = usePointOfSaleContext();
  const formikRef = React.useRef();

  React.useEffect(() => {
    if (salesLog?.id) {
      formikRef.current.resetForm({
        values: {
          saleDate: salesLog.saleDate,
          buyerBusiness: salesLog.buyerBusiness,
          pickupLocation: salesLog.pickupLocation,
          notes: salesLog.notes,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesLog?.id]);

  React.useEffect(() => {
    // If the sales log has changed elsewhere, reset the form to reflect the new sales log
    if (
      salesLog?.buyerBusiness?.id !==
        formikRef.current.values.buyerBusiness?.id ||
      salesLog?.notes !== formikRef.current.values.notes ||
      salesLog?.pickupLocation?.id !==
        formikRef.current.values.pickupLocation?.id ||
      salesLog?.saleDate !== formikRef.current.values.saleDate
    ) {
      formikRef.current.resetForm({
        values: {
          saleDate: salesLog.saleDate,
          buyerBusiness: salesLog.buyerBusiness,
          pickupLocation: salesLog.pickupLocation,
          notes: salesLog.notes,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesLog]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        saleDate: salesLog?.saleDate,
        buyerBusiness: salesLog?.buyerBusiness,
        pickupLocation: salesLog?.pickupLocation,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        SalesLogService.updateSalesLog({ ...salesLog, ...values })
          .then(setSalesLog)
          .catch((e) => addErrorAlert("Error updating sales log", e));
      }}
    >
      {() => (
        <Form data-testid="point-of-sale-form">
          <AutoSubmitOnChange />
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, m: 2 }}>
            {Boolean(salesLog?.invoiceNumber) && (
              <Box
                sx={{
                  display: "flex",
                  width: "fit-content",
                  flexDirection: "column",
                }}
              >
                <Typography variant="caption">Invoice #</Typography>
                <Typography variant="body1" fontSize="140%">
                  {salesLog?.invoiceNumber}
                </Typography>
              </Box>
            )}
            <Box>
              <Field
                component={FormDatePicker}
                name="saleDate"
                label="Sale Date"
                sx={{ width: 165 }}
              />
            </Box>
            <Box>
              <Field
                component={BusinessAutocomplete}
                name="buyerBusiness"
                label="Buyer"
                sx={{ width: 300 }}
                size="medium"
              />
            </Box>
            <Box>
              <Field
                component={PickupLocationAutocomplete}
                name="pickupLocation"
                label="Pickup Option"
                sx={{ width: 300 }}
                size="medium"
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
