import { Add, Delete, Edit, Remove } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import BusinessDisplay from "../../common/components/data-display/BusinessDisplay";
import { ProductInstanceSummary } from "../../common/components/product/ProductInstanceSummary";
import { useAlerts } from "../../common/context/AlertContext";
import SalesLogService from "../../common/service/SalesLogService";
import { displayPrice } from "../../common/util/DisplayUtil";
import { usePointOfSaleContext } from "./PointOfSaleContextProvider";
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function DeleteProductButton({ product, ...props }) {
  const { salesLog, setSalesLog } = usePointOfSaleContext();
  const { addConfirmAlert, addErrorAlert, addSuccessAlert } = useAlerts();
  return (
    <IconButton
      color="error"
      size="large"
      onClick={(e) => {
        e.stopPropagation();
        addConfirmAlert({
          message: "Do you want to remove this item from your cart?",
          onConfirm: () =>
            SalesLogService.deleteSaleItem(salesLog.id, product.id)
              .then((updatedSalesLog) => {
                setSalesLog(updatedSalesLog);
                addSuccessAlert("Item removed from cart");
              })
              .catch((e) => {
                addErrorAlert("Error removing item from cart", e);
              }),
          onReject: () => {},
        });
      }}
      data-testid="remove-item"
      {...props}
    >
      <Delete />
    </IconButton>
  );
}

export default function PointOfSaleCartItem({ item, ...props }) {
  const { addErrorAlert, addSuccessAlert, addConfirmAlert } = useAlerts();
  const { salesLog, setSalesLog, setCartItemToEdit } = usePointOfSaleContext();
  const [quantity, setQuantity] = useState(item.quantity);
  const [submitting, setSubmitting] = useState(false);

  function updateQuantity(newQuantity) {
    setSubmitting(true);
    setQuantity(newQuantity);
    SalesLogService.updateSaleItem(salesLog.id, {
      ...item,
      quantity: newQuantity,
    })
      .then((updatedSalesLog) => {
        setSalesLog(updatedSalesLog);
      })
      .catch((e) => {
        setQuantity(item.quantity);
        addErrorAlert("Error updating quantity", e);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <Paper
      data-testid="cart-item-summary"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
        width: "100%",
        p: 1,
        m: 0.5,
      }}
      elevation={3}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "space-between",
        }}
      >
        <ProductInstanceSummary
          productInstance={item}
          sx={{ width: "100%" }}
          elevation={0}
        />
      </Box>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <BusinessDisplay business={item.supplier} sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex", flexDirection: "column", flexShrink: 1 }}>
          <Typography variant="body1" fontWeight="bold">
            {displayPrice(item.unitPrice * item.quantity)}
          </Typography>
          {item.quantity > 1 && (
            <Typography variant="body2">
              {displayPrice(item.unitPrice)}/ea
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 0, alignItems: "center" }}>
          <Button
            data-testid="decrement-button"
            variant="outlined"
            size="small"
            sx={{
              height: "40px",
              minWidth: "44px",
              maxWidth: "44px",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderTopLeftRadius: "50%",
              borderBottomLeftRadius: "50%",
            }}
            style={{ minWidth: 40 }}
            aria-label={`Decrement quantity for ${item.name}`}
            onClick={() => updateQuantity(item.quantity - 1)}
            disabled={submitting || quantity <= 1}
          >
            <Remove />
          </Button>
          <TextField
            data-testid="quantity-text"
            onBlur={(e) => {
              const newQuantity = parseInt(e.target.value);
              if (item.quantity !== newQuantity) {
                if (newQuantity < 1) {
                  addConfirmAlert({
                    message: "Do you want to remove this item from your cart?",
                    onConfirm: () =>
                      SalesLogService.deleteSaleItem(salesLog.id, item.id)
                        .then((updatedSalesLog) => {
                          setSalesLog(updatedSalesLog);
                          addSuccessAlert("Item removed from cart");
                        })
                        .catch((e) => {
                          addErrorAlert("Error removing item from cart", e);
                        }),
                    onReject: () => {
                      setQuantity(item.quantity);
                    },
                  });
                } else {
                  updateQuantity(newQuantity);
                }
              }
            }}
            value={quantity}
            onChange={(e) => {
              const newQuantity = parseInt(e.target.value) || 0;
              setQuantity(newQuantity);
            }}
            disabled={submitting}
            size="small"
            sx={{ width: "55px", textAlign: "center" }}
            InputProps={{
              sx: {
                borderRadius: 0,
                borderLeft: "none",
                borderRight: "none",
              },
            }}
            inputProps={{
              style: {
                textAlign: "center",
              },
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
          <Button
            data-testid="increment-button"
            variant="outlined"
            sx={{
              height: "40px",
              minWidth: "44px",
              maxWidth: "44px",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: "50%",
              borderBottomRightRadius: "50%",
            }}
            aria-label={`Increment quantity for ${item.name}`}
            onClick={() => updateQuantity(item.quantity + 1)}
            disabled={submitting}
          >
            <Add />
          </Button>
        </Box>
        <DeleteProductButton
          product={item}
          sx={{ ml: 0.5, height: "fit-content", width: "fit-content" }}
        />
        <IconButton
          onClick={() => setCartItemToEdit(item)}
          aria-label="edit item"
          data-testid="edit-item"
          size="large"
          sx={{ height: "fit-content" }}
          color="primary"
        >
          <Edit />
        </IconButton>
      </Box>
    </Paper>
  );
}
