import { Container, Typography } from "@mui/material";
import * as Yup from "yup";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults";
import SalesLogService from "../../../common/service/SalesLogService";
import AuditPaymentSalesLogSummary from "./AuditPaymentSalesLogSummary";
import AuditPaymentsSearchCriteria from "./AuditPaymentsSearchCriteria";

export default function AuditPayments() {
  const defaultSearchCriteria = {
    invoiceNumber: "",
    page: 1,
    itemsPerPage: 10,
    buyerBusinesses: [],
    status: "SUBMITTED",
    paymentStatus: "",
    excludeEmptySales: true,
  };

  return (
    <Container data-testid="audit-payments-page">
      <Typography variant="h1">Audit Payments</Typography>
      <PaginatedSearchResults
        fetchSearchResults={SalesLogService.getSalesLogs}
        defaultSearchCriteria={defaultSearchCriteria}
        SearchCriteriaComponent={AuditPaymentsSearchCriteria}
        ResultDisplayComponent={AuditPaymentSalesLogSummary}
        validationSchema={Yup.object().shape({
          invoiceNumber: Yup.string(),
          buyerBusinesses: Yup.array(),
          paymentStatus: Yup.string(),
        })}
        normalizeSearchCriteria={(criteria) => {
          const cleanedCriteria = Object.fromEntries(
            Object.entries(criteria).filter(([_, v]) => v)
          );
          return cleanedCriteria;
        }}
      />
    </Container>
  );
}
