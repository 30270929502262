import { Clear } from "@mui/icons-material";
import { Box, Button, Paper } from "@mui/material";
import { FastField, Field } from "formik";
import React from "react";
import DebouncedTextField from "../../common/components/form/DebouncedTextField";
import ProductCategoryAutocomplete from "../../common/components/form/ProductCategoryAutocomplete";

const BaseProductSearchCriteria = ({ clearFilters }) => {
  return (
    <Paper
      sx={{ position: "sticky", top: 0, p: 1, zIndex: 1 }}
      elevation={0}
      data-testid="point-of-sale-other-items-search-criteria"
    >
      <Box
        sx={{ display: "flex", gap: 2, flexWrap: "wrap", alignItems: "center" }}
      >
        <Box>
          <Field
            component={DebouncedTextField}
            label="Search..."
            name="searchText"
          />
        </Box>
        <Box>
          <FastField
            component={ProductCategoryAutocomplete}
            name="category"
            label="Product Category"
            sx={{ width: 200 }}
          />
        </Box>
        <Button
          onClick={clearFilters}
          size="medium"
          variant="outlined"
          color="error"
          startIcon={<Clear />}
          sx={{ marginLeft: "auto" }}
        >
          Clear Filters
        </Button>
      </Box>
    </Paper>
  );
};

export default BaseProductSearchCriteria;
