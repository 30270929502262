import { Skeleton } from "@mui/material";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAlerts } from "../../../common/context/AlertContext";
import SalesLogService from "../../../common/service/SalesLogService";

const SalesLogContext = createContext();

export const SalesLogContextProvider = ({ children }) => {
  const { salesLogId } = useParams();
  const [salesLog, setSalesLog] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addErrorAlert } = useAlerts();

  useEffect(() => {
    if (salesLogId && !salesLog) {
      setLoading(true);
      SalesLogService.getSalesLogById(salesLogId)
        .then(setSalesLog)
        .catch((err) => addErrorAlert("Error fetching sales log", err))
        .finally(() => setLoading(false));
    }
  }, [salesLogId, addErrorAlert, salesLog]);

  return (
    <SalesLogContext.Provider value={{ salesLog, setSalesLog }}>
      {loading ? <Skeleton variant="rectangular" height={400} /> : children}
    </SalesLogContext.Provider>
  );
};

export const useSalesLogContext = () => useContext(SalesLogContext);
