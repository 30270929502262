import { InputAdornment } from "@mui/material";
import { FormTextField } from "./FormTextField";

export function FormCurrencyField(props) {
  return (
    <FormTextField
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*(\\.[0-9]{0,2})?",
      }}
      {...props}
    />
  );
}
