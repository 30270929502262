import axios from "axios";

const API_URL = process.env.REACT_APP_API_PATH + "/pickupLocation";

const PickupLocationService = {
  async findAll() {
    const response = await axios.get(`${API_URL}`);
    return response.data;
  },
};

export default PickupLocationService;
