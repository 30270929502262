import { Paper } from "@mui/material";
import { Field } from "formik";
import React from "react";
import BusinessAutocomplete from "../../../common/components/form/BusinessAutocomplete";
import DebouncedTextField from "../../../common/components/form/DebouncedTextField";
import FormSelect from "../../../common/components/form/FormSelect";

export default function AuditPaymentsSearchCriteria() {
  return (
    <Paper
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        p: 1,
        display: "flex",
        gap: 1.5,
        flexWrap: "wrap",
      }}
      elevation={0}
    >
      <Field
        component={DebouncedTextField}
        name="invoiceNumber"
        label="Invoice Number"
        sx={{ maxWidth: 145, width: "100%" }}
        size="small"
      />
      <Field
        component={BusinessAutocomplete}
        name="buyerBusinesses"
        label="Buyer Business"
        buyersOnly
        multiple
        sx={{ maxWidth: 270, width: "100%" }}
      />
      <Field
        component={FormSelect}
        name="paymentStatus"
        label="Payment Status"
        options={[
          { value: "", label: "-- Select an Option --" },
          { value: "NOT_SPECIFIED", label: "Not Audited" },
          { value: "Yes", label: "Paid" },
          { value: "No", label: "Not Paid" },
        ]}
        sx={{ maxWidth: 150, width: "100%" }}
      />
    </Paper>
  );
}
