import { Save } from "@mui/icons-material";
import { Button, Container, Skeleton, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-router-dom";
import * as Yup from "yup";
import FormColorInput from "../../../common/components/form/FormColorInput.jsx";
import { FormTextField } from "../../../common/components/form/FormTextField.jsx";
import { useAlerts } from "../../../common/context/AlertContext.jsx";
import { useCollective } from "../../../common/context/CollectiveContext.jsx";
import CollectiveService from "../../../common/service/CollectiveService.js";

const defaultCollectiveInfo = {
  name: "",
  primaryColor: "",
  secondaryColor: "",
  properties: {
    howToPayInstructions: "",
    replyToEmail: "",
    contactPhone: "",
  },
};

const EditCollective = () => {
  const { reloadCollective } = useCollective();
  const [loading, setLoading] = useState(true);
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const formikRef = React.useRef();
  const [collectiveInfo, setCollectiveInfo] = useState(defaultCollectiveInfo);

  useEffect(() => {
    setLoading(true);
    CollectiveService.getCurrentCollectiveInfo()
      .then((response) => {
        setCollectiveInfo(response);
        formikRef.current.setValues({ ...response });
      })
      .catch((error) => addErrorAlert("Error fetching collective info", error))
      .finally(() => setLoading(false));
  }, [addErrorAlert]);

  const handleSubmit = async (collectiveInfoToSave) => {
    return CollectiveService.updateCollective(
      collectiveInfo.id,
      collectiveInfoToSave
    )
      .then((updatedCollective) => {
        formikRef.current.resetForm({ values: updatedCollective });
        reloadCollective();
        addSuccessAlert("Changes saved");
      })
      .catch((error) => {
        addErrorAlert("Error saving changes", error);
      });
  };

  return (
    <Container sx={{ pt: 1.5 }} data-testid="edit-collective-container">
      <Typography variant="h1" gutterBottom data-testid="edit-collective-title">
        Configure Collective
      </Typography>
      {loading && <Skeleton variant="rectangular" height={200} />}
      <Formik
        initialValues={collectiveInfo}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
          primaryColor: Yup.string().required("Required"),
          secondaryColor: Yup.string().required("Required"),
          properties: Yup.object().shape({
            howToPayInstructions: Yup.string().required("Required"),
            replyToEmail: Yup.string()
              .email("Invalid email")
              .required("Required"),
            contactPhone: Yup.string().required("Required"),
          }),
        })}
      >
        {(props) => (
          <Form autoComplete="off" data-testid="edit-collective-form">
            <Typography variant="h4" gutterBottom>
              Collective Info
            </Typography>
            {!loading && collectiveInfo && (
              <Grid container spacing={2}>
                <Grid xs={12} md={5} container spacing={2} alignContent="start">
                  <Grid xs={12}>
                    <Field component={FormTextField} label="Name" name="name" />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <Field
                      component={FormColorInput}
                      label="Primary Color"
                      name="primaryColor"
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <Field
                      component={FormColorInput}
                      label="Secondary Color"
                      name="secondaryColor"
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} md={7}>
                  <Grid container spacing={3} columns={12}>
                    <Grid xs={12}>
                      <Field
                        component={FormTextField}
                        label="How To Pay Instructions"
                        name="properties.howToPayInstructions"
                        multiline
                        rows={4}
                        placeholder="To pay, mail a check to..."
                        helperText="Instructions for how to pay for orders. This will appear in emails sent to customers."
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid xs={12} md={7}>
                      <Field
                        component={FormTextField}
                        label="Reply To Email Address"
                        name="properties.replyToEmail"
                        type="email"
                        helperText="Email address for customer replies to order confirmations and other emails."
                      />
                    </Grid>
                    <Grid xs={12} md={5}>
                      <Field
                        component={FormTextField}
                        label="Contact Phone"
                        name="properties.contactPhone"
                        type="tel"
                        placeholder="123-456-7890"
                        helperText="Phone number for customer inquiries."
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Button
                    sx={{ m: 2 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={<Save />}
                    disabled={props.isSubmitting}
                    onClick={props.submitForm}
                    data-testid="save-button"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default EditCollective;
