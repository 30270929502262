import { CheckCircle, Warning } from "@mui/icons-material";
import { Box, Button, Link, ListItem, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import BusinessDisplay from "../../../common/components/data-display/BusinessDisplay";
import FormSelect from "../../../common/components/form/FormSelect";
import { FormTextField } from "../../../common/components/form/FormTextField";
import { useAlerts } from "../../../common/context/AlertContext";
import SalesLogService from "../../../common/service/SalesLogService";
import { displayPrice } from "../../../common/util/DisplayUtil";

const AuditPaymentSalesLogSummary = ({ item, ...props }) => {
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  return (
    <ListItem
      sx={{
        mb: 0.25,
        p: 1,
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: 1,
        border: "1px solid #E0E0E0",
        gap: 1,
      }}
      aria-label={`View invoice #${item.invoiceNumber}`}
      {...props}
      data-testid={"sales-log-summary"}
    >
      <Box
        sx={{
          display: "flex",
          minWidth: 0,
          ml: 2,
          mr: 0,
          flexWrap: "wrap",
          gap: 1,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">Invoice #</Typography>
          <Link
            component={RouterLink}
            to={`/app/business-admin/manage-sales-log/${item.id}`}
            sx={{ fontWeight: 800, display: "inline-block" }}
            data-testid="invoice-number"
          >
            {item.invoiceNumber}
          </Link>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">Sale Date</Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: 800, display: "inline-block" }}
            data-testid="sale-date"
          >
            {moment(item.saleDate).format("ddd, M/D/YY")}
          </Typography>
        </Box>
        {
          <BusinessDisplay
            business={item.buyerBusiness}
            label="Buyer"
            sx={{ width: 220 }}
          />
        }
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlgin: "end",
          }}
        >
          <Typography variant="caption" sx={{ alignSelf: "flex-end" }}>
            Total
          </Typography>
          <Typography
            variant="body1"
            sx={{
              alignSelf: "flex-end",
              fontWeight: 800,
              width: "fit-content",
              display: "inline-block",
              textAlign: "end",
            }}
            data-testid="sale-total"
          >
            {displayPrice(item.total)}
          </Typography>
        </Box>
        <Formik
          initialValues={{
            id: item.payment?.id,
            status: item.payment?.status,
            notes: item.payment?.notes,
          }}
          onSubmit={async (values) => {
            return SalesLogService.updateSalesLogPayment(item.id, values)
              .then(() => {
                addSuccessAlert("Payment changes saved");
              })
              .catch((e) => {
                addErrorAlert("Error changing payment info", e);
              });
          }}
        >
          {({ handleChange, handleSubmit }) => (
            <Form autoComplete="off">
              <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={1}>
                <Field
                  component={FormSelect}
                  name="status"
                  label="Paid?"
                  options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                  ]}
                  onChange={(e) => {
                    handleChange(e);
                    handleSubmit();
                  }}
                  renderValue={(option) => (
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      {option === "No" && <Warning color="error" />}
                      {option === "Yes" && <CheckCircle color="success" />}
                      <Typography>{option}</Typography>
                    </Box>
                  )}
                  sx={{ width: "110px" }}
                />
                <Field
                  component={FormTextField}
                  multiline
                  name="notes"
                  label="Notes"
                  sx={{ width: 300 }}
                />
                <Button type="submit">Update</Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </ListItem>
  );
};

export default AuditPaymentSalesLogSummary;
