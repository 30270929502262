import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import React from "react";

export default function FormCheckboxWithLabel({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  label,
  ...props
}) {
  return (
    <FormControl error={touched[name] && Boolean(errors[name])}>
      <FormControlLabel
        sx={{ width: "fit-content" }}
        id={name}
        data-testid={name}
        label={label}
        control={
          <Checkbox
            name={name}
            data-testid={name + "-checkbox"}
            checked={value === "true" || value === true ? true : false}
            onChange={onChange}
            onBlur={onBlur}
            {...props}
          />
        }
        {...props}
      />
      {touched[name] && Boolean(errors[name]) && (
        <FormHelperText error>{errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
}
