import { Avatar, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { displayName } from "../../util/DisplayUtil";
import TypographyEllipsis from "./TypographyEllipsis";

export default function UserDisplay({
  user,
  label = "",
  isLink = false,
  sx,
  ...props
}) {
  const linkProps =
    isLink && user?.firstName
      ? {
          component: Link,
          to: "/app/business-admin/manage-users/" + user.id,
        }
      : {};
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        ...sx,
      }}
      {...props}
    >
      {user?.firstName && (
        <>
          <Avatar src={user.profilePicPath} sx={{ height: 36, width: 36 }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              flexGrow: 1,
              minWidth: 0,
            }}
          >
            {label && <Typography variant="caption">{label}</Typography>}
            <TypographyEllipsis
              variant="body1"
              {...linkProps}
              data-testid="user-display"
            >
              {displayName(user.firstName, user.lastName)}
            </TypographyEllipsis>
          </Box>
        </>
      )}
    </Box>
  );
}
