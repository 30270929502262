import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { displayAdvancedRequestName } from "../../common/util/DisplayUtil";

export default function AdvancedRequestsBreadcrumbs({
  selectedRequest,
  adminView = false,
}) {
  return (
    <Breadcrumbs aria-label="product request breadcrumb">
      {selectedRequest ? (
        <Link
          to={
            adminView
              ? "/app/business-admin/manage-requests"
              : "/app/buyer/product-requests"
          }
        >
          Product Requests
        </Link>
      ) : (
        <Typography color="textPrimary">Product Requests</Typography>
      )}
      {selectedRequest && (
        <Typography color="text.primary">
          {displayAdvancedRequestName(selectedRequest)}
        </Typography>
      )}
    </Breadcrumbs>
  );
}
