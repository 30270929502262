import { Container, Typography } from "@mui/material";
import moment from "moment";
import * as Yup from "yup";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults";
import SalesLogService from "../../../common/service/SalesLogService";
import ManageSalesLogsSearchCriteria from "./ManageSalesLogsSearchCriteria";
import SalesLogSummary from "./SalesLogSummary";

export default function ManageSalesLogs() {
  const defaultSearchCriteria = {
    invoiceNumber: "",
    page: 1,
    itemsPerPage: 10,
    buyerBusinesses: [],
    saleDate: null,
    productInSale: null,
    paymentMethod: null,
    excludeEmptySales: true,
    pickupLocation: null,
    status: null,
  };

  return (
    <Container data-testid="manage-sales-logs-page">
      <Typography variant="h1">Sales Logs</Typography>
      <PaginatedSearchResults
        fetchSearchResults={SalesLogService.getSalesLogs}
        defaultSearchCriteria={defaultSearchCriteria}
        SearchCriteriaComponent={ManageSalesLogsSearchCriteria}
        ResultDisplayComponent={SalesLogSummary}
        validationSchema={Yup.object().shape({
          invoiceNumber: Yup.string(),
          buyerBusinesses: Yup.array(),
          saleDate: Yup.mixed()
            .nullable()
            .typeError("Invalid Date")
            .test("4-digit-year-required", "Invalid Date", (value) => {
              return value ? moment(value).year() > 1000 : true;
            }),
        })}
        normalizeSearchCriteria={(criteria) => {
          const cleanedCriteria = Object.fromEntries(
            Object.entries(criteria).filter(([_, v]) => v)
          );
          if (
            cleanedCriteria.saleDate &&
            typeof cleanedCriteria.saleDate !== "string"
          ) {
            cleanedCriteria.saleDate =
              cleanedCriteria.saleDate.format("YYYY-MM-DD");
          }
          return cleanedCriteria;
        }}
      />
    </Container>
  );
}
