import {
  Box,
  Button,
  Container,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import * as Yup from "yup";
import BusinessDisplay from "../../common/components/data-display/BusinessDisplay";
import { FormDatePicker } from "../../common/components/form/FormDatePicker";
import { useAlerts } from "../../common/context/AlertContext";
import { useCollective } from "../../common/context/CollectiveContext";
import FinancialReportingService from "../../common/service/reporting/FinancialReportingService";
import { displayPercentage, displayPrice } from "../../common/util/DisplayUtil";

const validationSchema = Yup.object({
  startDate: Yup.date()
    .typeError("Enter a valid date")
    .required("Start date is required"),
  endDate: Yup.date()
    .typeError("Enter a valid date")
    .required("End date is required"),
});

export default function SupplierTotals() {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const { addErrorAlert } = useAlerts();
  const { collectiveInfo } = useCollective();

  const total = results.reduce((acc, result) => acc + result.totalSales, 0);
  const totalCollectiveShare =
    total - results.reduce((acc, result) => acc + result.supplierShareTotal, 0);

  return (
    <Container sx={{ mt: 1 }}>
      <Typography variant="h4" gutterBottom>
        Supplier Sales Totals
      </Typography>
      <Formik
        initialValues={{
          startDate: moment().startOf("year").format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          values.startDate = moment(values.startDate).format("YYYY-MM-DD");
          values.endDate = moment(values.endDate).format("YYYY-MM-DD");
          setLoading(true);
          try {
            const data = await FinancialReportingService.getSupplierSalesTotals(
              values.startDate,
              values.endDate
            );
            setResults(data);
          } catch (e) {
            addErrorAlert("Error fetching supplier totals", e);
          }
          setLoading(false);
        }}
      >
        {(formik) => (
          <Form data-testid="supplier-totals-form">
            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Field
                component={FormDatePicker}
                name="startDate"
                label="Start Date"
                sx={{ width: 180 }}
              />
              <Field
                component={FormDatePicker}
                name="endDate"
                label="End Date"
                sx={{ width: 180 }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                data-testid="submit-button"
              >
                Submit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>

      <Paper sx={{ p: 2, mt: 2, width: "fit-content" }}>
        <Table
          data-testid="results-table"
          size="small"
          sx={{ width: "fit-content", mt: 2 }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Supplier</TableCell>
              <TableCell>Supplier %</TableCell>
              <TableCell>Total Sales</TableCell>
              <TableCell>Supplier Share</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                  <Skeleton variant="rectangular" height={100} sx={{ mb: 1 }} />
                  <Skeleton variant="rectangular" height={100} />
                  <Skeleton variant="rectangular" height={100} />
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              results.map((result) => (
                <TableRow key={result.supplier.id}>
                  <TableCell>
                    <BusinessDisplay business={result.supplier} />
                  </TableCell>
                  <TableCell sx={{ textAlign: "end" }}>
                    {displayPercentage(result.supplierSharePercent)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "end" }}>
                    {displayPrice(result.totalSales)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "end" }}>
                    {displayPrice(result.supplierShareTotal)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {results?.length > 0 && (
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <Typography variant="h6">
              Total {collectiveInfo.name} Share:{" "}
              <strong>{displayPrice(totalCollectiveShare)}</strong>
            </Typography>
            <Typography variant="h6">
              Total Sales: <strong>{displayPrice(total)}</strong>
            </Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
}
