import { Box, Button, Divider, Tooltip } from "@mui/material";
import React from "react";

import { CardMembership, Yard } from "@mui/icons-material";
import MainContentWithResponsiveDrawer from "../../common/components/data-display/MainContentWithResponsiveDrawer";
import AdvancedRequestsInSalesLog from "./AdvancedRequestsInSalesLog";
import PointOfSaleBaseDetailsForm from "./PointOfSaleBaseDetailsForm";
import PointOfSaleCart from "./PointOfSaleCart";
import { usePointOfSaleContext } from "./PointOfSaleContextProvider";
import PointOfSaleInventorySearch from "./PointOfSaleInventorySearch";
import PointOfSaleOtherItemsSearch from "./PointOfSaleOtherItemsSearch";
import PointOfSaleQuickSelectOptions from "./PointOfSaleQuickSelectOptions";
import PresaleOrdersInSalesLog from "./PresaleOrdersInSalesLog";

export default function PointOfSale({ isNew }) {
  const {
    salesLog,
    isShoppingInventory,
    setIsShoppingInventory,
    setBackToAdvancedRequest,
    setBackToPresaleOrder,
    isShoppingOther,
    setIsShoppingOther,
  } = usePointOfSaleContext();
  const isBuyerSelected = Boolean(salesLog.buyerBusiness);
  return (
    <MainContentWithResponsiveDrawer
      mainContent={
        <Box data-testid="point-of-sale-content">
          <Box sx={{ display: isShoppingInventory ? "block" : "none" }}>
            <PointOfSaleInventorySearch />
          </Box>
          <Box sx={{ display: isShoppingOther ? "block" : "none" }}>
            <PointOfSaleOtherItemsSearch />
          </Box>
          <Box
            sx={{
              display:
                isShoppingInventory || isShoppingOther ? "none" : "block",
            }}
          >
            <PointOfSaleBaseDetailsForm />
            <Divider />
            <PointOfSaleQuickSelectOptions />
            <Divider />
            <AdvancedRequestsInSalesLog />
            <PresaleOrdersInSalesLog />
            <Box
              display="flex"
              justifyContent={"center"}
              sx={{ mt: 2, width: "100%", gap: 2 }}
              flexWrap="wrap"
            >
              <Tooltip title={isBuyerSelected ? "" : "Select a buyer first"}>
                <div>
                  <Button
                    size="large"
                    onClick={() => {
                      setIsShoppingInventory(true);
                      // When coming from main menu, reset back button callbacks to hide them
                      setBackToAdvancedRequest(null);
                      setBackToPresaleOrder(null);
                    }}
                    variant="contained"
                    startIcon={<Yard />}
                    disabled={!isBuyerSelected}
                  >
                    Shop Floral Inventory
                  </Button>
                </div>
              </Tooltip>
              <Tooltip title={isBuyerSelected ? "" : "Select a buyer first"}>
                <div>
                  <Button
                    size="large"
                    onClick={() => {
                      setIsShoppingOther(true);
                      // When coming from main menu, reset back button callbacks to hide them
                      setBackToAdvancedRequest(null);
                      setBackToPresaleOrder(null);
                    }}
                    variant="contained"
                    startIcon={<CardMembership />}
                    disabled={!isBuyerSelected}
                  >
                    Shop Other Items
                  </Button>
                </div>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      }
      drawerContent={<PointOfSaleCart view="shopping" />}
      mobileOpenDrawerContent={"View cart"}
    />
  );
}
