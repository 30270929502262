import { PhotoCamera, Save } from "@mui/icons-material";
import {
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Container,
  Fab,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2.js";
import { FastField, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form, useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import TabPanel, {
  a11yTabProps,
} from "../../../common/components/data-display/TabPanel.jsx";
import FormCheckboxWithLabel from "../../../common/components/form/FormCheckboxWithLabel.jsx";
import FormSelect from "../../../common/components/form/FormSelect.jsx";
import { FormTextField } from "../../../common/components/form/FormTextField.jsx";
import { useAlerts } from "../../../common/context/AlertContext.jsx";
import BusinessService from "../../../common/service/BusinessService.js";
import UserService from "../../../common/service/UserService.js";
import EditBusinessUsers from "./EditBusinessUsers.jsx";

const defaultBusinessInfo = {
  name: "",
  streetAddress1: "",
  streetAddress2: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  businessEmail: "",
  url: "",
  logoPath: "",
  preferredContactMethod: "",
  ownerFirstName: "",
  ownerLastName: "",
  buyerStatus: "",
  supplierStatus: "",
  supplierCode: "",
  buyerCode: "",
  platinumMember: false,
  mou: false,
  nda: false,
  taxFormsReceived: false,
  paymentContactPhone: "",
  defaultPickupLocation: "",
  supplierSharePercent: "",
  notes: "",
};

const EditBusiness = () => {
  const [loading, setLoading] = useState(true);
  const [loadingBusinessUsers, setLoadingBusinessUsers] = useState(true);
  const [loadingBusinessLogo, setLoadingBusinessLogo] = useState(false);
  const [businessUsers, setBusinessUsers] = useState(true);
  const navigate = useNavigate();
  const { businessId } = useParams();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const formikRef = React.useRef();
  const location = useLocation();
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    location.state?.businessTab || 0
  );
  useEffect(() => {
    if (!businessId) {
      return;
    } else if (businessId === "new") {
      setBusinessInfo(defaultBusinessInfo);
      setBusinessUsers([]);
      setLoadingBusinessUsers(false);
      setLoading(false);
    } else {
      fetchBusinessInfo(businessId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId]);

  async function fetchBusinessInfo(businessId) {
    setLoading(true);
    try {
      const response = await BusinessService.getBusinessById(businessId);
      setBusinessInfo(response);
      setLoading(false);
      formikRef.current?.setValues({ ...response });
    } catch (error) {
      addErrorAlert("Error fetching business", error);
    }
    try {
      const response = await UserService.getUsersByBusinessId(businessId);
      setBusinessUsers(response);
      setLoadingBusinessUsers(false);
    } catch (error) {
      addErrorAlert("Error fetching business users", error);
    }
  }

  const [businessInfo, setBusinessInfo] = useState(defaultBusinessInfo);

  const handleSubmit = async (businessInfoToSave) => {
    if (businessId === "new") {
      return BusinessService.createBusiness(businessInfoToSave).then(
        (createdBusiness) => {
          navigate(
            `/app/business-admin/manage-businesses/${createdBusiness.id}`,
            {
              replace: true,
            }
          );
          formikRef.current.resetForm({ values: createdBusiness });
          addSuccessAlert("Business created");
        }
      );
    } else {
      return BusinessService.updateBusiness(
        businessId,
        businessInfoToSave
      ).then((updatedBusiness) => {
        formikRef.current.resetForm({ values: updatedBusiness });
        addSuccessAlert("Changes saved");
      });
    }
  };
  return (
    <Container sx={{ pt: 1.5 }}>
      <Typography variant="h1" gutterBottom id="edit-business-heading">
        {loading ? (
          <Skeleton />
        ) : businessId === "new" ? (
          "Add New Business"
        ) : (
          businessInfo?.name
        )}
      </Typography>
      <Tabs
        value={selectedTabIndex}
        onChange={(e, v) => {
          navigate(null, { state: { businessTab: v }, replace: true });
          setSelectedTabIndex(v);
        }}
        aria-label="business admin tabs"
      >
        <Tab label="Basic Info" {...a11yTabProps(0)} />
        <Tab
          label={`Users (${loadingBusinessUsers ? "..." : businessUsers?.length})`}
          {...a11yTabProps(1)}
          disabled={businessId === "new"}
        />
      </Tabs>
      <TabPanel value={selectedTabIndex} index={0}>
        <Formik
          initialValues={businessInfo}
          onSubmit={handleSubmit}
          innerRef={formikRef}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Required"),
            businessEmail: Yup.string()
              .email("Invalid email")
              .required("Required"),
            preferredContactMethod: Yup.string().required("Required"),
          })}
        >
          {(props) => (
            <Form autoComplete="off">
              {loading ? (
                <>
                  <Skeleton variant="text" />
                  <Skeleton variant="rectangular" />
                  <Skeleton variant="rectangular" />
                </>
              ) : (
                <>
                  <Grid container spacing={4}>
                    <Grid container spacing={2} xs={12} md={7}>
                      <Grid xs={12} container spacing={1}>
                        <Grid xs={12}>
                          <Typography variant="h4">Basic Info</Typography>
                        </Grid>
                        <Grid xs={12}>
                          <FastField
                            component={FormTextField}
                            label="Business Name"
                            name="name"
                            required
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid xs={12}>
                          <Typography variant="h4">Contact Info</Typography>
                        </Grid>
                        <Grid xs={12} sm={6} md={4}>
                          <FastField
                            component={FormTextField}
                            label="Owner's First Name"
                            name="ownerFirstName"
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={4}>
                          <FastField
                            component={FormTextField}
                            label="Owner's Last Name"
                            name="ownerLastName"
                          />
                        </Grid>
                        <Grid xs={12} md={4}>
                          <FastField
                            component={FormSelect}
                            name="preferredContactMethod"
                            label={"Contact Method"}
                            options={[
                              { value: "Email", label: "Email" },
                              { value: "Phone", label: "Phone" },
                              { value: "SMS", label: "SMS" },
                            ]}
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <FastField
                            component={FormTextField}
                            label="Business Email"
                            name="businessEmail"
                            type="email"
                            required
                          />
                        </Grid>
                        <Grid xs={12} sm={6} lg={3}>
                          <FastField
                            component={FormTextField}
                            label="Phone Number"
                            name="phone"
                          />
                        </Grid>
                        <Grid xs={12} sm={6} lg={3}>
                          <FastField
                            component={FormTextField}
                            label="Payment Phone"
                            name="paymentContactPhone"
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid xs={12}>
                          <Typography variant="h4">Address</Typography>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <FastField
                            component={FormTextField}
                            label="Address 1"
                            name="streetAddress1"
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <FastField
                            component={FormTextField}
                            label="Address 2"
                            name="streetAddress2"
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <FastField
                            component={FormTextField}
                            label="City"
                            name="city"
                          />
                        </Grid>
                        <Grid xs={6} md={3}>
                          <FastField
                            component={FormTextField}
                            label="State"
                            name="state"
                          />
                        </Grid>
                        <Grid xs={6} md={3}>
                          <FastField
                            component={FormTextField}
                            label="ZIP"
                            name="zip"
                          />
                        </Grid>

                        <Grid xs={12}>
                          <FastField
                            component={FormTextField}
                            label="Notes"
                            name="notes"
                            multiline
                            rows={2}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} xs={12} md={5}>
                      <Grid xs={12} container>
                        <Grid>
                          <Typography variant="h4">
                            Business Account Info
                          </Typography>
                        </Grid>
                        {businessId !== "new" && (
                          <Grid xs={12}>
                            <Card
                              sx={{
                                width: 150,
                                height: 150,
                                position: "relative",
                                margin: "auto",
                              }}
                            >
                              {businessInfo.logoPath && (
                                <CardMedia
                                  id="business-logo-display"
                                  component="img"
                                  image={businessInfo.logoPath}
                                  sx={{
                                    height: "100%",
                                    width: "100%",
                                  }}
                                />
                              )}
                              <Tooltip title="Select New Logo" placement="top">
                                <Fab
                                  sx={{
                                    position: "absolute",
                                    right: 4,
                                    bottom: 4,
                                  }}
                                  size="small"
                                  onClick={() =>
                                    document
                                      .getElementById("change-logo-btn")
                                      .click()
                                  }
                                  disabled={loadingBusinessLogo}
                                >
                                  {loadingBusinessLogo ? (
                                    <CircularProgress size="small" />
                                  ) : (
                                    <PhotoCamera />
                                  )}
                                </Fab>
                              </Tooltip>
                              <input
                                id={"change-logo-btn"}
                                type="file"
                                accept="image"
                                value=""
                                hidden
                                onChange={async (e) => {
                                  setLoadingBusinessLogo(true);
                                  try {
                                    const updatedBusinessInfo =
                                      await BusinessService.updateBusinessLogo(
                                        businessId,
                                        e.target.files[0]
                                      );
                                    setBusinessInfo({
                                      ...businessInfo,
                                      logoPath: updatedBusinessInfo.logoPath,
                                    });
                                    addSuccessAlert("Logo updated");
                                  } catch (error) {
                                    addErrorAlert(
                                      "Error uploading logo",
                                      error
                                    );
                                  } finally {
                                    setLoadingBusinessLogo(false);
                                  }
                                }}
                              />
                            </Card>
                          </Grid>
                        )}
                        <Grid xs={12}>
                          <FastField
                            component={FormTextField}
                            label="Website / Social Media URL"
                            name="url"
                            autoCapitalize={"off"}
                          />
                        </Grid>
                      </Grid>
                      <Grid container xs={12} spacing={1.5}>
                        <Grid xs={12} container>
                          <Grid xs={12}>
                            <Typography variant="h4">
                              Business Status
                              {businessInfo.id && ` - ID: ${businessInfo.id}`}
                            </Typography>
                          </Grid>
                          <Grid xs={6}>
                            <FastField
                              component={FormSelect}
                              name="buyerStatus"
                              label={"Buyer Status"}
                              options={[
                                { value: "yes", label: "Active" },
                                { value: "no", label: "Inactive" },
                              ]}
                            />
                          </Grid>
                          <Grid xs={6}>
                            <FastField
                              component={FormTextField}
                              name="buyerCode"
                              label={"Buyer Code"}
                            />
                          </Grid>
                          <Grid xs={6}>
                            <FastField
                              component={FormSelect}
                              name="supplierStatus"
                              label={"Supplier Status"}
                              options={[
                                { value: "yes", label: "Active" },
                                { value: "no", label: "Inactive" },
                              ]}
                            />
                          </Grid>
                          <Grid xs={6}>
                            <FastField
                              component={FormTextField}
                              name="supplierCode"
                              label={"Supplier Code"}
                            />
                          </Grid>
                          <Grid xs={6}>
                            <FastField
                              component={FormTextField}
                              name="supplierSharePercent"
                              label={"Supplier Share %"}
                            />
                          </Grid>
                          <Grid xs={12} container spacing={0}>
                            <Grid xs={12} md={6}>
                              <FastField
                                component={FormCheckboxWithLabel}
                                name="platinumMember"
                                label={"Platinum Member?"}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <FastField
                                component={FormCheckboxWithLabel}
                                name="mou"
                                label={"MoU?"}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <FastField
                                component={FormCheckboxWithLabel}
                                name="nda"
                                label={"NDA Signed?"}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <FastField
                                component={FormCheckboxWithLabel}
                                name="taxFormsReceived"
                                label={"Tax Forms Received?"}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Button
                    sx={{ m: 2 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={<Save />}
                    disabled={props.isSubmitting}
                    onClick={props.submitForm}
                  >
                    Save
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        <EditBusinessUsers
          businessId={businessId}
          businessUsers={businessUsers}
          setBusinessUsers={setBusinessUsers}
        />
      </TabPanel>
    </Container>
  );
};

export default EditBusiness;
