import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import BusinessDisplay from "../../../common/components/data-display/BusinessDisplay";
import BusinessAutocomplete from "../../../common/components/form/BusinessAutocomplete";
import { FormCurrencyField } from "../../../common/components/form/FormCurrencyField";
import { FormQuantityField } from "../../../common/components/form/FormQuantityField";
import { ProductInstanceSummary } from "../../../common/components/product/ProductInstanceSummary";
import { useAlerts } from "../../../common/context/AlertContext";
import { displayPrice } from "../../../common/util/DisplayUtil";

const ProductQuantitySelectDialog = ({
  open,
  onClose,
  onEditQuantity,
  productInstance,
  onAddProduct,
  isEditing,
  showSupplier = false,
  allowOversell = false,
  allowEditingPrice = false,
  allowEditingSupplier = false,
}) => {
  const { addErrorAlert } = useAlerts();
  const optionalValidationPieces = {};
  if (allowEditingPrice) {
    optionalValidationPieces.unitPrice = Yup.number()
      .typeError("Must be a number")
      .min(0, "Unit price must be greater than 0")
      .required("Unit price is required");
  }
  if (allowEditingSupplier) {
    optionalValidationPieces.supplier = Yup.mixed()
      .test("supplier-required", "Supplier is required", (value) => {
        return !allowEditingSupplier || value;
      })
      .nullable();
  }
  // An item that is already in the cart will have a presaleOrderItemId
  const validationSchema = Yup.object({
    quantity: Yup.number()
      .typeError("Must be a number")
      .min(1, "Quantity must be greater than 0")
      .required("Quantity is required")
      .test("quantity-available", "Quantity exceeds available", (value) => {
        if (!productInstance.quantityAvailable) {
          return true;
        }
        return allowOversell || value <= productInstance.quantityAvailable;
      }),
    ...optionalValidationPieces,
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid="product-quantity-select-dialog"
    >
      <DialogTitle data-testid="dialog-title">
        Select Product Quantity
      </DialogTitle>
      <Formik
        initialValues={{
          quantity: isEditing ? productInstance?.quantity : "",
          unitPrice: parseFloat(
            productInstance?.estimatedUnitPrice ||
              productInstance?.unitPrice ||
              productInstance?.defaultPrice ||
              0
          ).toFixed(2),
          supplier:
            productInstance?.supplier ||
            productInstance?.supplierBusiness ||
            null,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (isEditing) {
              return await onEditQuantity({
                ...productInstance,
                quantity: values.quantity,
                unitPrice: values.unitPrice,
                supplier: values.supplier,
              });
            } else {
              return await onAddProduct({
                productInstanceId: productInstance.isBaseProduct
                  ? undefined
                  : productInstance.id,
                baseProductId: productInstance.isBaseProduct
                  ? productInstance.id
                  : undefined,
                ...productInstance,
                quantity: values.quantity,
                unitPrice: values.unitPrice,
                supplier: values.supplier,
              });
            }
          } catch (err) {
            addErrorAlert("Error completing request", err);
          }
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form autoComplete="off">
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    flexDirection: "column",
                    alignItems: "end",
                  }}
                >
                  <ProductInstanceSummary item={productInstance} />
                  {showSupplier && !allowEditingSupplier && (
                    <BusinessDisplay business={productInstance.supplier} />
                  )}
                  {allowEditingSupplier && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                      }}
                    >
                      <Field
                        component={BusinessAutocomplete}
                        suppliersOnly
                        name="supplier"
                        label="Supplier"
                        sx={{ width: 300 }}
                        size="medium"
                      />
                    </Box>
                  )}
                  {!isEditing && Boolean(productInstance.quantityAvailable) && (
                    <Box>
                      <Typography textAlign="end">
                        Quantity Available: {productInstance.quantityAvailable}
                      </Typography>
                    </Box>
                  )}
                  {Boolean(values.unitPrice) && !allowEditingPrice && (
                    <Typography variant="body2" textAlign="end">
                      Unit Cost: {displayPrice(values.unitPrice)}
                    </Typography>
                  )}
                  {allowEditingPrice && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                      }}
                    >
                      <Field
                        component={FormCurrencyField}
                        name="unitPrice"
                        label="Unit Price"
                        sx={{ width: 100 }}
                        size="medium"
                      />
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                    }}
                  >
                    <Field
                      autoFocus
                      component={FormQuantityField}
                      name="quantity"
                      label="Quantity"
                      sx={{ width: 100 }}
                      size="medium"
                    />
                  </Box>
                  <Typography textAlign="end">
                    {values.unitPrice && values.quantity && (
                      <strong>
                        Total:{" "}
                        {displayPrice(values.unitPrice * values.quantity)}
                      </strong>
                    )}
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "space-between" }}>
                <Button
                  onClick={onClose}
                  color="primary"
                  data-testid="cancel-button"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting ? <CircularProgress size={16} /> : null
                  }
                  data-testid="submit-quantity-button"
                >
                  {isEditing ? "Update Cart" : "Add to Cart"}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ProductQuantitySelectDialog;
