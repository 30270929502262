import { Box, FormHelperText } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import BusinessAutocomplete from "../../common/components/form/BusinessAutocomplete";
import { FormDatePicker } from "../../common/components/form/FormDatePicker";
import { FormTextField } from "../../common/components/form/FormTextField";
import PickupLocationAutocomplete from "../../common/components/form/PickupLocationAutocomplete";
import { useAlerts } from "../../common/context/AlertContext";
import { useCollective } from "../../common/context/CollectiveContext";
import AdvancedRequestService from "../../common/service/AdvancedRequestService";
import { isValidPickupDate } from "../../common/util/DatePickerUtilFrontend";
import { displayName } from "../../common/util/DisplayUtil";
import { useAdvancedRequestContext } from "./AdvancedRequestFormContext";

export default function AdvancedRequestBaseForm({ isNew, setFormikRef }) {
  const { collectiveInfo } = useCollective();
  const { advancedRequest, setAdvancedRequest } = useAdvancedRequestContext();
  const { addErrorAlert } = useAlerts();
  const formikRef = React.useRef();
  const navigate = useNavigate();

  React.useEffect(() => {
    setFormikRef(formikRef);
  }, [setFormikRef]);

  const handleSubmit = async (requestToSave) => {
    if (isNew) {
      return AdvancedRequestService.createAdvancedRequest(requestToSave)
        .then((createdRequest) => {
          setAdvancedRequest(createdRequest);
          navigate(`/app/buyer/product-requests/${createdRequest.id}/edit`, {
            replace: true,
          });
          return createdRequest;
        })
        .catch((err) => addErrorAlert("Error creating request", err));
    } else {
      return AdvancedRequestService.updateAdvancedRequest(
        advancedRequest.id,
        requestToSave
      )
        .then((updatedRequest) => {
          setAdvancedRequest(updatedRequest);
          return updatedRequest;
        })
        .catch((err) => addErrorAlert("Error saving changes", err));
    }
  };

  function isValidDate(date) {
    if (advancedRequest.pickupDate) {
      // Disable dates that are not the same week as the original pickup date
      if (date.isoWeek() !== moment(advancedRequest.pickupDate).isoWeek()) {
        return false;
      }
    }
    return isValidPickupDate(date, collectiveInfo);
  }

  return (
    <Formik
      initialValues={advancedRequest}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      validationSchema={Yup.object().shape({
        pickupDate: Yup.date()
          .required("Pickup date is required")
          .test("valid-date", "Invalid date", (val) => {
            const date = moment(val);
            return isValidDate(date);
          }),
        orderReference: Yup.string().nullable(),
        pickupLocation: Yup.object().required("Pickup option is required"),
        buyerBusiness: Yup.object().required("Buyer business is required"),
      })}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <Form>
            <Grid container columnSpacing={2} rowGap={2}>
              <Grid xs={12} sm={5} md={4} mdOffset={1} lg={3} lgOffset={2}>
                <Field
                  component={FormDatePicker}
                  name="pickupDate"
                  label="Pickup Date"
                  required
                  disablePast
                  shouldDisableDate={(d) => !isValidDate(d)}
                  maxDate={moment().add(4, "weeks")}
                />
              </Grid>
              <Grid xs={12} sm={7} md={6} lg={5}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Field
                    component={BusinessAutocomplete}
                    name="buyerBusiness"
                    label="Ordering Business"
                    size="medium"
                    buyersOnly
                    required
                    onlyUsersBusinesses
                  />
                  {advancedRequest.createdBy?.id && (
                    <FormHelperText>
                      Submitted by{" "}
                      <Link
                        to={`/app/business-admin/manage-users/${advancedRequest.createdBy.id}`}
                      >
                        {displayName(
                          advancedRequest.createdBy?.firstName,
                          advancedRequest.createdBy?.lastName
                        )}
                      </Link>
                      {` ${moment(advancedRequest.createdAt).fromNow()}`}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid xs={12} md={5} mdOffset={1} lgOffset={2} lg={4}>
                <Field
                  component={PickupLocationAutocomplete}
                  name="pickupLocation"
                  label="Pickup Option"
                  required
                  size="medium"
                />
              </Grid>
              <Grid xs={12} md={5} lg={4}>
                <Field
                  component={FormTextField}
                  name="orderReference"
                  label="Order Reference"
                  size="medium"
                  placeholder='Eg, "Order for Summer Event"'
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
