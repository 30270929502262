import React, { useState } from "react";
import { FormTextField } from "./FormTextField";

const DebouncedTextField = ({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  debounceMs = 300,
  setLoading,
  ...props
}) => {
  const [displayValue, setDisplayValue] = useState(value);
  const [debounceTimeout, setDebounceTimeout] = useState();

  React.useEffect(() => {
    if (!value && displayValue) {
      // If the value is programmatically cleared, clear the display value
      setDisplayValue("");
    } else if (value !== displayValue && !debounceTimeout) {
      // If the value is programmatically set, update the display value
      setDisplayValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChangeHandler = props.onChange || onChange;
  return (
    <FormTextField
      {...props}
      field={{
        name,
        onChange: (e) => {
          // If setLoading prop has been passed, set it to true so loading spinner can begin
          setLoading?.(true);
          const newValue = e.target.value;
          clearTimeout(debounceTimeout);
          setDisplayValue(newValue);
          setDebounceTimeout(
            setTimeout(() => {
              onChangeHandler(
                {
                  target: {
                    name,
                    value: newValue,
                  },
                },
                newValue
              );
              // Clear the debounce timeout after running
              setDebounceTimeout(null);
            }, debounceMs)
          );
        },
        onBlur,
        value: displayValue,
      }}
      form={{ touched, errors }}
    />
  );
};

export default DebouncedTextField;
