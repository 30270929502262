import { ListOutlined, ShoppingBag } from "@mui/icons-material";
import { Alert, Button, Container, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../../common/context/AuthContext";
import { useCollective } from "../../common/context/CollectiveContext";
import { isPresaleShoppingAvailable } from "../../common/util/DatePickerUtilFrontend";

export default function BuyerHome() {
  // If location.state.cancelCart is true, show an alert that their cart has been canceled
  const locationState = useLocation().state;
  const cartExpired = Boolean(locationState?.cartExpired);
  const { collectiveInfo } = useCollective();

  const isPresaleOpen = isPresaleShoppingAvailable(collectiveInfo);
  const { isMemberOfPlatinumBuyer } = useAuthContext();

  return (
    <Container
      style={{ textAlign: "center", marginTop: "20px" }}
      data-testid="buyer-home-container"
    >
      <Typography variant="h4" gutterBottom data-testid="buyer-home-title">
        Buyer Home
      </Typography>
      <Grid container spacing={2} direction="column" alignItems={"center"}>
        <Grid>
          <Tooltip
            title={
              !isMemberOfPlatinumBuyer
                ? `Only platinum members of ${collectiveInfo.name} can shop the presale`
                : !isPresaleOpen
                  ? "The presale is closed"
                  : null
            }
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="presale/shop"
                style={{ marginTop: "20px" }}
                data-testid="presale-button"
                startIcon={<ShoppingBag />}
                disabled={!isMemberOfPlatinumBuyer || !isPresaleOpen}
              >
                Shop the Presale
              </Button>
            </div>
          </Tooltip>
          {cartExpired && (
            <Alert severity="warning" sx={{ mt: 1.5, maxWidth: 300 }}>
              Your cart expired - any items in your cart were returned.
            </Alert>
          )}
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="my-presale-orders"
            style={{ marginTop: "20px" }}
            data-testid="my-presale-orders-button"
            startIcon={<ListOutlined />}
          >
            View Orders
          </Button>
        </Grid>
        <Grid>
          <Tooltip
            title={
              !isMemberOfPlatinumBuyer
                ? `Only platinum members of ${collectiveInfo.name} can make product requests`
                : null
            }
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="product-requests"
                style={{ marginTop: "20px" }}
                data-testid="advanced-product-requests-button"
                disabled={!isMemberOfPlatinumBuyer}
              >
                Advanced Product Requests
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </Container>
  );
}
