import axios from "axios";

export const SALES_LOG_BASE_URL = process.env.REACT_APP_API_PATH + "/salesLog";

const SalesLogService = {
  async getSalesLogs(searchCriteria) {
    const response = await axios.post(
      SALES_LOG_BASE_URL + "/search",
      searchCriteria
    );
    return response.data;
  },
  getSalesLogById: async (id) => {
    const response = await axios.get(SALES_LOG_BASE_URL + "/" + id);
    return response.data;
  },
  createSalesLog: async (salesLog) => {
    const response = await axios.post(SALES_LOG_BASE_URL, salesLog);
    return response.data;
  },
  updateSalesLog: async (salesLog) => {
    const response = await axios.put(
      SALES_LOG_BASE_URL + "/" + salesLog.id,
      salesLog
    );
    return response.data;
  },
  updateSalesLogPaymentMethod: async (salesLogId, paymentMethod) => {
    const response = await axios.put(
      SALES_LOG_BASE_URL + "/" + salesLogId + "/paymentMethod",
      paymentMethod
    );
    return response.data;
  },
  submitSalesLog: async (salesLogId) => {
    const response = await axios.put(
      SALES_LOG_BASE_URL + "/" + salesLogId + "/submit"
    );
    return response.data;
  },
  addSaleItem: async (salesLogId, saleItem) => {
    const response = await axios.post(
      SALES_LOG_BASE_URL + "/" + salesLogId + "/saleItem",
      saleItem
    );
    return response.data;
  },
  updateSaleItem: async (salesLogId, saleItem) => {
    const response = await axios.put(
      SALES_LOG_BASE_URL + "/" + salesLogId + "/saleItem/" + saleItem.id,
      saleItem
    );
    return response.data;
  },
  deleteSaleItem: async (salesLogId, saleItemId) => {
    const response = await axios.delete(
      SALES_LOG_BASE_URL + "/" + salesLogId + "/saleItem/" + saleItemId
    );
    return response.data;
  },
  updateSalesLogPayment: async (salesLogId, payment) => {
    const response = await axios.put(
      `${SALES_LOG_BASE_URL}/${salesLogId}/payment/${payment.id}`,
      payment
    );
    return response.data;
  },
  addPresaleOrder: async (salesLogId, presaleOrderId) => {
    const response = await axios.post(
      `${SALES_LOG_BASE_URL}/${salesLogId}/presaleOrder/${presaleOrderId}`
    );
    return response.data;
  },
  removePresaleOrder: async (salesLogId, presaleOrderId) => {
    const response = await axios.delete(
      `${SALES_LOG_BASE_URL}/${salesLogId}/presaleOrder/${presaleOrderId}`
    );
    return response.data;
  },
  addAdvancedRequest: async (salesLogId, advancedRequestId) => {
    const response = await axios.post(
      `${SALES_LOG_BASE_URL}/${salesLogId}/advancedRequest/${advancedRequestId}`
    );
    return response.data;
  },
  removeAdvancedRequest: async (salesLogId, advancedRequestId) => {
    const response = await axios.delete(
      `${SALES_LOG_BASE_URL}/${salesLogId}/advancedRequest/${advancedRequestId}`
    );
    return response.data;
  },
  async getItemsSoldForSupplier(searchCriteria) {
    const response = await axios.post(
      `${SALES_LOG_BASE_URL}/itemsSold`,
      searchCriteria
    );
    return response.data;
  },
  async resendReceipt(salesLogId) {
    const response = await axios.put(
      `${SALES_LOG_BASE_URL}/${salesLogId}/resendReceipt`
    );
    return response.data;
  },
  async downloadReceipt(salesLogId) {
    const response = await axios.get(
      `${SALES_LOG_BASE_URL}/${salesLogId}/downloadReceipt`,
      { responseType: "blob" }
    );
    return response.data;
  },
};

export default SalesLogService;
