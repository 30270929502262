import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { displayPrice } from "../../common/util/DisplayUtil";

export default function SalesLogCostSummary({
  salesLog,
  calculateTotal = false,
}) {
  let { subtotal, transactionFeeAmount, total, salesTaxAmount } = salesLog;

  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography data-testid="subtotal-label">Subtotal:</Typography>
        <Typography data-testid="subtotal-value">
          {displayPrice(subtotal)}
        </Typography>
      </Box>
      {transactionFeeAmount > 0 && (
        <Box display="flex" justifyContent="space-between" mt={1}>
          <Typography data-testid="transaction-fee-label">
            Transaction Fee:
          </Typography>
          <Typography data-testid="transaction-fee-value">
            {displayPrice(transactionFeeAmount)}
          </Typography>
        </Box>
      )}
      {salesTaxAmount > 0 && (
        <Box display="flex" justifyContent="space-between" mt={1}>
          <Typography data-testid="tax-label">Tax:</Typography>
          <Typography data-testid="tax-value">
            {displayPrice(salesTaxAmount)}
          </Typography>
        </Box>
      )}
      <Divider />
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="h6" data-testid="total-label">
          Total:
        </Typography>
        <Typography variant="h6" fontWeight="bold" data-testid="total-value">
          {displayPrice(total)}
        </Typography>
      </Box>
    </Box>
  );
}
