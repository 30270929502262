import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { FastField, Form, Formik } from "formik";
import React from "react";
import { FormCurrencyField } from "../../../common/components/form/FormCurrencyField";
import { useAlerts } from "../../../common/context/AlertContext";
import BaseProductService from "../../../common/service/BaseProductService";
import PriceGroupAttributeSelector from "./PriceGroupAttributeSelector";

export default function EditProductGroupPriceDialog({
  open,
  onClose,
  baseProduct,
  priceGroup,
  onCreate,
  onUpdate,
  onDelete,
}) {
  const { addSuccessAlert, addErrorAlert } = useAlerts();
  const isNew = !Boolean(priceGroup?.id);
  const formikRef = React.useRef();
  async function handleSubmit(values) {
    if (isNew) {
      return BaseProductService.addProductPriceGroup(values)
        .then((productPriceGroups) => {
          addSuccessAlert("Price group created");
          onCreate(productPriceGroups);
        })
        .catch((error) => {
          addErrorAlert("Error creating price group", error);
        });
    } else {
      return BaseProductService.updateProductPriceGroup(values)
        .then((productPriceGroups) => {
          addSuccessAlert("Price changes saved");
          onUpdate(productPriceGroups);
        })
        .catch((error) => {
          addErrorAlert("Error saving price group", error);
        });
    }
  }

  async function deletePriceGroup() {
    if (window.confirm("Are you sure you want to delete this price group?")) {
      return BaseProductService.deleteProductPriceGroup(priceGroup.id)
        .then(() => {
          addSuccessAlert("Price group deleted");
          onDelete(priceGroup.id);
        })
        .catch((error) => {
          addErrorAlert("Error deleting price group", error);
        });
    }
  }

  React.useEffect(() => {
    if (priceGroup) {
      formikRef.current?.resetForm({ values: priceGroup });
    }
  }, [priceGroup]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      id="edit-pricing-group-dialog"
    >
      <Formik
        initialValues={priceGroup}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {() => (
          <Form autoComplete="off">
            <DialogTitle>{isNew ? "Add" : "Edit"} Price Group</DialogTitle>

            <DialogContent>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid xs={12}>
                  <PriceGroupAttributeSelector
                    baseProduct={baseProduct}
                    priceGroup={priceGroup}
                  />
                </Grid>
                <Grid xs={12}>
                  <FastField
                    component={FormCurrencyField}
                    name="price"
                    label="Price"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button type="button" onClick={onClose}>
                Cancel
              </Button>
              {
                <Button type="button" onClick={deletePriceGroup}>
                  Delete
                </Button>
              }
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
