import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, styled, Toolbar, Typography } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import React from "react";
import { Link } from "react-router-dom";
import { NAV_DRAWER_WIDTH } from "../../pages/UserHome";
import { useCollective } from "../context/CollectiveContext";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: NAV_DRAWER_WIDTH,
    width: `calc(100% - ${NAV_DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function MainAppBar({ open, handleDrawerOpen, hideMenuButton }) {
  const { collectiveInfo } = useCollective();

  return (
    <AppBar position="fixed" open={open} color="primary">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...((open || hideMenuButton) && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Link to="/">
          <Typography
            sx={{
              width: "fit-content",
              display: "inline-block",
              color: "#fff",
            }}
            variant="h1"
          >
            {collectiveInfo?.name}
          </Typography>
        </Link>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: "none", md: "flex", alignItems: "center" } }}>
          <Typography variant="body2">Powered by </Typography>
          <img
            src="/img/picklinq-solutions/SVG/Picklinq_Horizontal_White.svg"
            alt="Picklinq"
            style={{ height: 48, marginLeft: 10 }}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
