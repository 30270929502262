import { ArrowLeft } from "@mui/icons-material";
import { Box, Button, Container, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import SelectableBusinessDisplay from "../../../common/components/data-display/SelectableBusinessDisplay";
import { useAuthContext } from "../../../common/context/AuthContext";
import PresaleCart from "./PresaleCart";
import { usePresaleOrderContext } from "./PresaleOrderFormContext";

export default function PresaleOrderReview() {
  const { presaleOrder, setPresaleOrder } = usePresaleOrderContext();
  const { defaultBuyer } = useAuthContext();

  React.useEffect(() => {
    if (presaleOrder && !presaleOrder.buyerBusiness?.id && defaultBuyer) {
      setPresaleOrder({
        ...presaleOrder,
        buyerBusiness: defaultBuyer,
      });
    }
  }, [presaleOrder, defaultBuyer, setPresaleOrder]);

  return (
    <Container>
      {presaleOrder && (
        <Grid container spacing={2}>
          <Grid xs={12} sm={5}>
            <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
              <Typography variant="h4">Review Order</Typography>
              <Divider />
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography variant="h6">Buyer: </Typography>
                <SelectableBusinessDisplay
                  selectedBusiness={presaleOrder.buyerBusiness}
                  setSelectedBusiness={(business) => {
                    setPresaleOrder({
                      ...presaleOrder,
                      buyerBusiness: business,
                    });
                  }}
                  buyersOnly
                  onlyUsersBusinesses
                  allowChangingDefault
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography variant="h6">Market Date: </Typography>
                <Typography variant="body1">
                  {moment(presaleOrder.saleDate).format("dddd, LL")}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography variant="h6">Pickup Option: </Typography>
              <Typography variant="body1">
                {presaleOrder.pickupLocation}
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Button
                variant="outlined"
                component={Link}
                to="/app/buyer/presale/shop"
                sx={{ mt: 2 }}
                startIcon={<ArrowLeft />}
              >
                Edit Order
              </Button>
            </Box>
          </Grid>
          <Grid xs={12} sm={7}>
            <PresaleCart view="review" />
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
