import axios from "axios";

const API_URL = process.env.REACT_APP_API_PATH + "/paymentMethod";

const PaymentMethodService = {
  async findAll() {
    const response = await axios.get(`${API_URL}`);
    return response.data;
  },
  async create(paymentMethod) {
    const response = await axios.post(API_URL, paymentMethod);
    return response.data;
  },
  async update(id, paymentMethod) {
    const response = await axios.put(`${API_URL}/${id}`, paymentMethod);
    return response.data;
  },
  async remove(id) {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
  },
};

export default PaymentMethodService;
