import { Box, Paper } from "@mui/material";
import { Field } from "formik";
import BusinessAutocomplete from "../../../common/components/form/BusinessAutocomplete";
import DebouncedTextField from "../../../common/components/form/DebouncedTextField";
import { FormDatePicker } from "../../../common/components/form/FormDatePicker";
import FormSelect from "../../../common/components/form/FormSelect";
import { SALE_FOCUSES } from "../../Supplier/UploadInventory/UploadInventoryDialog";

export default function ManageInventorySearchCriteria() {
  return (
    <Paper
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        p: 1,
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
      }}
      elevation={0}
    >
      <Field
        component={DebouncedTextField}
        label="Search..."
        name="searchText"
        sx={{ maxWidth: 300, width: "100%" }}
      />
      <Field
        component={BusinessAutocomplete}
        name="businesses"
        label="Business"
        suppliersOnly
        multiple
        sx={{ maxWidth: 300, width: "100%" }}
      />
      <div>
        <Field
          component={FormDatePicker}
          name="dateStocked"
          label="Date Stocked"
          sx={{ maxWidth: 180, width: "100%" }}
          size="small"
        />
      </div>
      <Box sx={{ width: 180 }}>
        <Field
          component={FormSelect}
          name="saleFocus"
          label="Sale Focus"
          sx={{ width: "100%" }}
          options={[
            { label: "-- Select an option --", value: "" },
            ...SALE_FOCUSES,
          ]}
        />
      </Box>
    </Paper>
  );
}
