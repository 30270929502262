import { Add } from "@mui/icons-material";
import { Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults.jsx";
import UserService from "../../../common/service/UserService.js";
import UserSearchCriteria from "./UserSearchCriteria.jsx";
import UserSummary from "./UserSummary.jsx";

const ManageUsers = () => {
  const defaultSearchCriteria = {
    searchText: "",
    page: 1,
    itemsPerPage: 10,
  };
  return (
    <Container sx={{ pt: 1.5 }}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant="h2" gutterBottom>
            Manage Users
          </Typography>
        </Grid>
        <Grid item>
          <Link to="/app/business-admin/manage-users/new">
            <Button variant="contained" color="primary" startIcon={<Add />}>
              Add User
            </Button>
          </Link>
        </Grid>
      </Grid>
      <PaginatedSearchResults
        fetchSearchResults={UserService.getUsers}
        ResultDisplayComponent={UserSummary}
        SearchCriteriaComponent={UserSearchCriteria}
        defaultSearchCriteria={defaultSearchCriteria}
      />
    </Container>
  );
};

export default ManageUsers;
