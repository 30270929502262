import { ArrowBack, CheckSharp } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import BusinessDisplay from "../../common/components/data-display/BusinessDisplay";
import AutoSubmitOnChange from "../../common/components/form/AutoSubmitOnChange";
import PaymentMethodAutocomplete from "../../common/components/form/PaymentMethodAutocomplete";
import { ProductInstanceSummary } from "../../common/components/product/ProductInstanceSummary";
import ProductPriceDisplay from "../../common/components/product/ProductPriceDisplay";
import ProductQuantityChip from "../../common/components/product/ProductQuantityChip";
import { useAlerts } from "../../common/context/AlertContext";
import SalesLogService from "../../common/service/SalesLogService";
import PointOfSaleBaseDetailsForm from "./PointOfSaleBaseDetailsForm";
import { usePointOfSaleContext } from "./PointOfSaleContextProvider";
import SalesLogCostSummary from "./SalesLogCostSummary";

export default function PointOfSalePayment() {
  const { salesLog, setSalesLog } = usePointOfSaleContext();
  const { addErrorAlert } = useAlerts();
  const [submitting, setSubmitting] = React.useState(false);
  const navigate = useNavigate();
  const summaryComponents = [
    ({ product }) => (
      <BusinessDisplay sx={{ maxWidth: 150 }} business={product.supplier} />
    ),
    ProductQuantityChip,
    ProductPriceDisplay,
  ];

  return (
    <Container>
      <Grid container>
        <Grid
          container
          spacing={0}
          data-testid="sales-log-items"
          sx={{ mt: 1 }}
          xs={12}
          md={7}
          flexDirection={"column"}
        >
          <Button
            variant="outlined"
            onClick={() => navigate("/app/point-of-sale/" + salesLog.id)}
            startIcon={<ArrowBack />}
            size="large"
            sx={{
              width: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
              mb: 2,
            }}
          >
            Back to Cart
          </Button>
          <Grid xs={12}>
            <Typography variant="h5" gutterBottom>
              Sale Items
            </Typography>
          </Grid>
          <Grid xs={12}>
            {salesLog.items.map((item, index) => (
              <ProductInstanceSummary
                key={index}
                productInstance={item}
                summaryComponents={summaryComponents}
                hideOverflow={false}
                sx={{ height: "fit-content" }}
              />
            ))}
          </Grid>
        </Grid>
        <Grid xs={12} md={5}>
          <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
            Sale Details
          </Typography>
          <PointOfSaleBaseDetailsForm />
          <Box sx={{ display: "flex", gap: 2, p: 2 }}>
            <Formik
              initialValues={{
                paymentMethod: salesLog?.payment?.paymentMethod || null,
              }}
              onSubmit={async (values) => {
                return SalesLogService.updateSalesLogPaymentMethod(
                  salesLog.id,
                  values.paymentMethod
                )
                  .then((updatedSalesLog) => setSalesLog(updatedSalesLog))
                  .catch((e) =>
                    addErrorAlert("Error updating payment method", e)
                  );
              }}
              validationSchema={Yup.object().shape({
                paymentMethod: Yup.object().nullable().required("Required"),
              })}
            >
              {({ values }) => (
                <Form autoComplete="off">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <AutoSubmitOnChange />
                    <Field
                      component={PaymentMethodAutocomplete}
                      name="paymentMethod"
                      label="Payment Method"
                      sx={{ width: 300 }}
                      size="medium"
                    />
                    <Divider sx={{ mt: 2 }} />
                    <SalesLogCostSummary
                      salesLog={{
                        ...salesLog,
                        payment: {
                          ...salesLog.payment,
                          paymentMethod: values.paymentMethod,
                        },
                      }}
                    />
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
          <Button
            color={salesLog.invoiceNumber ? "primary" : "success"}
            variant="contained"
            fullWidth
            size="large"
            sx={{ fontSize: "130%" }}
            disabled={submitting}
            startIcon={
              submitting ? <CircularProgress size={16} /> : <CheckSharp />
            }
            onClick={() => {
              // Validate order details before submitting
              if (!salesLog.saleDate) {
                addErrorAlert("Please select a sale date");
                return;
              }
              if (!salesLog.buyerBusiness?.id) {
                addErrorAlert("Please select a buyer business");
                return;
              }
              if (!salesLog.payment?.paymentMethod) {
                addErrorAlert("Please select a payment method");
                return;
              }
              // If they already have an invoice number, no backend call is needed
              const submitPromise = salesLog.invoiceNumber
                ? Promise.resolve(salesLog)
                : SalesLogService.submitSalesLog(salesLog.id);
              submitPromise
                .then((response) => {
                  setSalesLog(response);
                  navigate(
                    "/app/business-admin/manage-sales-log/" + salesLog.id
                  );
                })
                .catch((e) => {
                  addErrorAlert("Error submitting sale", e.message);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {salesLog.invoiceNumber ? "Finish Editing" : "Submit Sale"}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
