import { Clear } from "@mui/icons-material";
import { Box, Button, Paper } from "@mui/material";
import { Field } from "formik";
import React from "react";
import BaseProductAutocomplete from "../../../common/components/form/BaseProductAutocomplete";
import BusinessAutocomplete from "../../../common/components/form/BusinessAutocomplete";
import DebouncedTextField from "../../../common/components/form/DebouncedTextField";
import { FormDatePicker } from "../../../common/components/form/FormDatePicker";
import FormSelect from "../../../common/components/form/FormSelect";
import PaymentMethodAutocomplete from "../../../common/components/form/PaymentMethodAutocomplete";
import PickupLocationAutocomplete from "../../../common/components/form/PickupLocationAutocomplete";

export default function ManageSalesLogsSearchCriteria({ clearFilters }) {
  return (
    <Paper
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        p: 1,
        display: "flex",
        gap: 1.5,
        flexWrap: "wrap",
      }}
      elevation={0}
    >
      <Field
        component={DebouncedTextField}
        name="invoiceNumber"
        label="Invoice Number"
        sx={{ maxWidth: 145, width: "100%" }}
        size="small"
      />
      <Field
        component={BusinessAutocomplete}
        name="buyerBusinesses"
        label="Buyer Business"
        buyersOnly
        multiple
        sx={{ maxWidth: 270, width: "100%" }}
      />
      <Box>
        <Field
          component={FormDatePicker}
          name="saleDate"
          label="Sale Date"
          sx={{ maxWidth: 200, width: "100%" }}
          size="small"
        />
      </Box>
      <Field
        component={BaseProductAutocomplete}
        name="productInSale"
        label="Product in Sale"
        sx={{ maxWidth: 270, width: "100%" }}
      />
      <Field
        component={BusinessAutocomplete}
        name="supplier"
        label="Supplier"
        suppliersOnly
        sx={{ maxWidth: 270, width: "100%" }}
      />
      <Field
        component={PaymentMethodAutocomplete}
        name="paymentMethod"
        label="Payment Method"
        sx={{ maxWidth: 180, width: "100%" }}
      />
      <Field
        component={PickupLocationAutocomplete}
        name="pickupLocation"
        label="Pickup Option"
        sx={{ maxWidth: 180, width: "100%" }}
      />
      <Field
        component={FormSelect}
        name="status"
        label="Status"
        options={[
          { value: "DRAFT", label: "Incomplete" },
          { value: "SUBMITTED", label: "Completed" },
        ]}
        sx={{ maxWidth: 150, width: "100%" }}
      />
      <Button
        onClick={clearFilters}
        size="medium"
        variant="outlined"
        color="error"
        startIcon={<Clear />}
        sx={{ marginLeft: "auto" }}
      >
        Clear Filters
      </Button>
    </Paper>
  );
}
